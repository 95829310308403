<template src="./deleteCampaignTemplateModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../common/AppModal/AppModal";
import { deleteEmailCampaignTemplate } from "../../../../../services/clubDetails/emailTemplate.service";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    ids: {
      type: Array,
      default: [],
    },
    selectedTemplateType: {
      type: Object,
      default: () => { },
    },
  },
  computed: {},
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      isLoader: false,

    };
  },
  methods: {
    deleteECT() {
      ;
      this.isLoader = true;
      var mod = {
        id: this.ids,
        clubId: this.$route.params.id,
      };

      deleteEmailCampaignTemplate(mod)
        .then((response) => {
          this.isLoader = false;
          this.$store.dispatch("getEmailCampaignTemplates", {
            clubId: this.$route.params.id,
            limit: -1,
            campaignGroup: this.$route.query.flow_name ? 2 : 1,
            templateType: this.selectedTemplateType.value,
          });
          this.$emit("closeDeleteModal", response.data.data);
        })
        .catch((error) => {
          this.isLoader = false;
        });
    },
    modalHandler(data) {
      this.$emit("closeDeleteModal", "cancelClicked");
    },
  },
};
</script>
