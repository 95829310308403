<template src="./PublishConfirmationModal.html"></template>
<style lang="scss">
@import "./PublishConfirmationModal.scss";
</style>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal.vue";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { TimePicker } from "@progress/kendo-vue-dateinputs";
import { DateTimePicker } from "@progress/kendo-vue-dateinputs";
import { changeTimeZone } from "@/utils/helper";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import moment from "moment";
import { filterBy } from "@progress/kendo-data-query";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
    radiogroup: RadioGroup,
    datePicker: DatePicker,
    numericTextBox: NumericTextBox,
    dropdownlist: DropDownList,
    timePicker: TimePicker,
    datetimepicker: DateTimePicker,
    AppSnackBar,
  },
  props: {
    prePayload: {
      type: Object,
      required: true,
    },
    fetchedInfo: {
      type: Object,
    },
    onCancel: { type: Function },
    onConfirm: { type: Function },
  },
  data: function () {
    let currDate = new Date();
    currDate.setDate(currDate.getDate() + 1);
    return {
      timeZoneLocal: Intl.DateTimeFormat().resolvedOptions().timeZone,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      clubId: this.$route.params.id,
      publishType: 1,
      scheduleType: 1,
      batchSize: 0.3,
      dateTimeValue: currDate,
      timeValue: new Date(),
      intervalItems: [
        { id: 1, text: "30 minutes", value: 30 },
        { id: 2, text: "1 hour", value: 60 },
        { id: 3, text: "6 hours", value: 360 },
        { id: 4, text: "12 hours", value: 720 },
        { id: 5, text: "24 hours", value: 1440 },
      ],
      intervalValue: { id: 1, text: "30 minutes", value: 30 },
      timeZoneValue: {},
      timeZoneClubValue: {},
      scheduleDate: new Date(),
      scheduleTime: new Date(),
      searchData: "",
      timeZone999: {},
      timeZoneWithOut999: {},
      dataItems: [
        {
          label: "Fixed Time",
          value: 1,
        },
        {
          label: "Send in batches",
          value: 2,
        },
      ],
    };
  },
  computed: {
    timeZonesList() {
      return this.$store.state.commonEmailCampaign.timeZoneList;
    },
    club() {
      return this.$store.state.club.club;
    },
  },

  watch: {
    timeZoneValue(newValue, oldValue) {
      this.updateDataItems(newValue);
    },
  },
  mounted() {
    this.timeZoneClubValue = this.timeZoneInit();
    this.timeZoneValue = this.timeZoneClubValue;
    // this.$store.dispatch("getTimeZonesList");
    if (this.$route?.params?.campaignId) {
      this.dataReload();
    } else {
      let timeZone = this.club.timeZoneId;
      this.timeZoneValue = this.timeZonesList.find(
        (item) => item.id === timeZone
      );
    }
  },

  methods: {
    updateDataItems(newValue) {
      if (newValue?.id === 999) {
        this.dataItems = this.dataItems.filter((item) => item.value === 1);
        this.scheduleType = 1;
      } else {
        this.dataItems = [
          { label: "Fixed Time", value: 1 },
          { label: "Send in batches", value: 2 },
        ];
      }
    },
    filterChange(event) {
      this.searchData = "";
      let data = this.$store.state.commonEmailCampaign.timeZoneTempList;
      if (this.scheduleType == 2) data = data.filter((zone) => zone.id !== 999);
      this.searchData = filterBy(data, event.filter);
      this.$store.commit("SET_TIME_ZONE_LIST", this.searchData);
    },

    timeZoneInit() {
      let timeZone = this.club.timeZoneId;

      if (timeZone != 999) {
        return this.timeZonesList.find((item) => item.id === timeZone);
      } else {
        return this.timeZonesList.find(
          (item) =>
            item.name === Intl.DateTimeFormat().resolvedOptions().timeZone
        );
      }
    },

    updateScaduleDate(event) {
      this.scheduleDate = event.target.value;
    },

    handleResumeWorkflowStartTime(e) {
      this.scheduleTime = e.value;
    },

    combineDateTime(dateString, timeString) {
      if (!dateString || !timeString) {
        return null;
      }

      const date = new Date(dateString);
      const time = new Date(timeString);

      if (isNaN(date.getTime()) || isNaN(time.getTime())) {
        return null;
      }

      const combinedDate = new Date(date);
      combinedDate.setHours(time.getHours());
      combinedDate.setMinutes(time.getMinutes());
      combinedDate.setSeconds(0);
      combinedDate.setMilliseconds(0);

      return combinedDate;
    },

    dataReload() {
      this.publishTypeClickHandler(this?.fetchedInfo?.publishOption);
      if (this?.fetchedInfo?.publishOption == 2) {
        this.scheduleType = this?.fetchedInfo?.scheduleType || 1;
        this.dateTimeValue = new Date(this?.fetchedInfo?.dateOfSchedule);
        this.scheduleDate = new Date(this?.fetchedInfo?.dateOfSchedule);
        this.scheduleTime = new Date(this?.fetchedInfo?.dateOfSchedule);
        this.timeZoneValue = this.timeZonesList.find((item) => {
          return item.id === this?.fetchedInfo?.timeZoneId;
        });
        if (this?.fetchedInfo?.scheduleType == 2) {
          this.batchSize = Number(this?.fetchedInfo?.batchSize) / 100;
          this.intervalValue = this.intervalItems.find((item) => {
            return item.value === this?.fetchedInfo?.intervalTime;
          });
        }
      }
    },
    updateDateAndTime(event) {
      this.dateTimeValue = event.target.value;
    },
    updateTime(event) {
      this.timeValue = event.target.value;
    },
    publishTypeClickHandler(type) {
      this.publishType = type != 0 ? type : 1;
      if (type == 1) {
        this.$refs.sendNow.classList.add("active");
        this.$refs.scheduled.classList.remove("active");
      }
      if (type == 2) {
        this.$refs.scheduled.classList.add("active");
        this.$refs.sendNow.classList.remove("active");
      }
    },
    handleGenderChangeValue(e) {
      this.intervalValue = e.value;
    },
    handleTimeZoneChangeValue(e) {
      this.timeZoneValue = e.value;
    },
    handleChange(e) {
      this.scheduleType = e.value;
      this.scheduleTypeFilter();
    },

    scheduleTypeFilter() {
      this.timeZone999 =
        this.$store?.state?.commonEmailCampaign?.timeZoneTempList?.find(
          (zone) => zone.id === 999
        );
      if (this.scheduleType === 1) {
        if (this.timeZone999.id === 999) {
          const data = this.$store.state.commonEmailCampaign.timeZoneTempList;
          this.$store.commit("SET_TIME_ZONE_LIST", [...data, this.timeZone999]);
        }
      }
      if (this.scheduleType === 2) {
        const filteredTimeZones = this.timeZonesList.filter(
          (zone) => zone.id != 999
        );
        this.$store.commit("SET_TIME_ZONE_LIST", filteredTimeZones);
      }
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },

    timeDifference() {
      const CurrentTime = moment(new Date());
      const givenTime = moment(this.dateTimeValue);
      return givenTime?.diff(CurrentTime, "minutes");
    },

    dataValidation() {
      if (this.publishType === 2 && this.timeDifference() <= 0) {
        this.showNotification(
          "You can’t select past time.Select a future time.",
          "warning"
        );
        return false;
      }

      if (this.publishType === 2 && this.timeDifference() < 30) {
        this.showNotification(
          "Select a time at least 30 minutes later from now.",
          "warning"
        );
        return false;
      }
      if (
        this.publishType === 2 &&
        this.timeZoneValue?.id == 999 &&
        this.timeDifference() < 1440
      ) {
        this.showNotification(
          "Select a time at least 24 hours later from now.",
          "warning"
        );
        return false;
      }
      return true;
    },

    onPublishHandler() {
      this.dateTimeValue = this.combineDateTime(
        this.scheduleDate,
        this.scheduleTime
      );
      if (this.dataValidation()) {
        let payload = {
          ...this.prePayload,
          publishOption: this.publishType,
          timeZoneId: this.timeZoneValue?.id,
          timezone: this.timeZoneLocal,
          isDynamicAudience: false,
          ...(this?.$route?.query?.templateId && {
            emailCampaignTemplateId: +this.$route.query.templateId,
          }),
        };
        // ;

        if (this.publishType == 1) {
          payload = {
            ...payload,
            dateOfSchedule: new Date().toUTCString(),
            timeOfSchedule: new Date().toUTCString(),
            utcTimeOfSend: new Date().toUTCString(),
            campaignStatus: 2,
          };
        } else {
          if (this.scheduleType == 1) {
            payload = {
              ...payload,
              dateOfSchedule: this.dateTimeValue,
              timeOfSchedule: this.dateTimeValue,
              utcTimeOfSend: changeTimeZone(
                this.dateTimeValue,
                this.timeZoneValue.id != 999
                  ? this.timeZoneValue?.name
                  : this.timeZoneClubValue?.name
              ),
              campaignStatus: 3,
              scheduleType: this.scheduleType,
            };
          }
          if (this.scheduleType === 2) {
            payload = {
              ...payload,
              dateOfSchedule: this.dateTimeValue,
              timeOfSchedule: this.dateTimeValue,
              utcTimeOfSend: changeTimeZone(
                this.dateTimeValue,
                this.timeZoneValue.id != 999
                  ? this.timeZoneValue?.name
                  : this.timeZoneClubValue?.name
              ),
              campaignStatus: 3,
              scheduleType: this.scheduleType,
              batchSize: Math.round(this.batchSize * 100),
              intervalTime: this.intervalValue?.value,
            };
          }
        }
        this.onConfirm(payload);
      }
    },
  },
};
</script>

<style></style>
