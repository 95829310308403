<template src="./createCampaignVerifyYourEmail.html"></template>
<style lang="scss">
@import "./createCampaignVerifyYourEmail.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import modal from "../../../../common/AppModal/AppModal.vue";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { addSenderDomainService } from "@/services/clubDetails/emailCampaign.service";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
export default {
  name: "createCampaignVerifyYourEmail",
  components: {
    kbutton: Button,
    modal,
    Grid,
    GridToolbar,
    GridNoRecords,
    AppLoader,
  },
  props: {
    onCancel: {
      type: Function,
      default: () => { },
    },
    onConfirm: {
      type: Function,
      default: () => { },
    },
    copyClipboardMessageHandler: {
      type: Function,
      default: () => { },
    },
    senderEmail: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      isLoader: true,
      domainDetails: null,
      tableData: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          field: "type",
          title: "Type",
        },
        {
          field: "host",
          title: "Host",
          cell: "hostCell",
        },
        {
          field: "value",
          title: "Value",
          cell: "valueCell",
        },
      ];
    },
  },
  mounted() {
    const payload = {
      id: 0,
      userEmail: this.senderEmail,
      domainName: this.senderEmail.split("@")[1],
      isVerified: false,
      clubId: this.$route.params.id,
    };
    this.addSenderDomainHandler(payload);
  },
  methods: {
    addSenderDomainHandler(payload) {
      this.isLoader = true;
      addSenderDomainService(payload)
        .then((res) => {
          this.isLoader = false;
          ;
          this.domainDetails = res?.data?.data;
          const dnsData = this.domainDetails?.dns;

          Object.keys(dnsData).forEach((key) => {
            this.tableData.push({
              type: dnsData[key].type,
              host: dnsData[key].host,
              value: dnsData[key].data,
            });
          });
        })
        .catch((err) => {
          this.isLoader = false;
        });
    },
    onVerify() {
      const payload = {
        id: this.domainDetails?.id,
        userEmail: this.senderEmail,
        domainName: this.senderEmail.split("@")[1],
        isVerified: true,
      };
      this.onConfirm(payload);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.copyClipboardMessageHandler();
    },
  },
};
</script>
