<modal
  :title="$route?.query?.nodeId ? 'Select Email Template' : 'Select Email Campaign Template' "
  :handler="true"
  :cssClass="'modal-xxl content-px-0'"
  :footer="false"
  @modalHandler="onCancel"
>
  <template v-slot:content>
    <AppLoader v-if="isLoading" />
    <AppSnackBar
      :message="notifyMessage"
      :notificationType="notifyType"
      v-if="isNotify"
    ></AppSnackBar>
    <div class="px-16 pb-16 max-height-100-182">
      <div class="select-campaign-template-menu">
        <div class="d-flex px-10 pb-40">
          <div v-for="(type) in templateTypeArray">
            <kbutton
              @click="onTemplateTypeHandler(type)"
              :class="'h-38 py-7 px-13 border-normal-1'"
              :size="'medium'"
              :theme-color="type?.isSelected ? 'primary' : ''"
              >{{type?.name}}
            </kbutton>
          </div>
        </div>
      </div>
      <div class="select-campaign-template-wrap">
        <div class="select-campaign-template-box text-center">
          <div class="content min-h-340">
            <div class="d-flex flex-column">
              <div
                class="d-flex align-items-center justify-content-center min-h-280"
              >
                <svg
                  width="110"
                  height="145"
                  viewBox="0 0 110 145"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M105.5 25L88.5 7V25H105.5Z" fill="white" />
                  <path
                    d="M105.5 25L88.5 7V25H105.5Z"
                    fill="#0052CC"
                    fill-opacity="0.12"
                  />
                  <g filter="url(#filter0_d_5324_85988)">
                    <path
                      d="M88.415 7H7C5.89543 7 5 7.89543 5 9V76.7917V140.595C5 141.7 5.89543 142.595 7 142.595H103.5C104.605 142.595 105.5 141.7 105.5 140.595V24.9464M88.415 7L105.5 24.9464M88.415 7V22.9464C88.415 24.051 89.3104 24.9464 90.415 24.9464H105.5"
                      stroke="#A4C3F3"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-dasharray="2.5 2.5"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <circle
                    cx="53.6546"
                    cy="52.9286"
                    r="23.9286"
                    fill="#A4C3F3"
                  />
                  <path
                    d="M52.097 54.5898H47.2739V51.8605H52.097V47H54.8264V51.8605H59.6495V54.5898H54.8264V59.388H52.097V54.5898Z"
                    fill="#F8F9FA"
                  />
                  <rect
                    opacity="0.5"
                    x="17.7617"
                    y="107.309"
                    width="74.9762"
                    height="6.38095"
                    rx="3.19048"
                    fill="#A4C3F3"
                  />
                  <rect
                    opacity="0.5"
                    x="17.7617"
                    y="92"
                    width="74.9762"
                    height="8"
                    rx="4"
                    fill="#A4C3F3"
                  />
                  <rect
                    opacity="0.5"
                    x="17.7617"
                    y="120.072"
                    width="55.4"
                    height="6.38095"
                    rx="3.19048"
                    fill="#A4C3F3"
                  />
                  <defs>
                    <filter
                      id="filter0_d_5324_85988"
                      x="0.556117"
                      y="0.406116"
                      width="109.388"
                      height="144.483"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="-2.15" />
                      <feGaussianBlur stdDeviation="1.84694" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5324_85988"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5324_85988"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div class="p-10">
                <kbutton
                  @click="navigateToTemplateEditor"
                  :class="'h-38 w-100'"
                  :size="'medium'"
                  :theme-color="'primary'"
                  >Build from Scratch</kbutton
                >
              </div>
            </div>
          </div>
          <h4 class="font-20 l-height-24 font-w-400 mb-0 mt-3">
            Build From Scratch
          </h4>
        </div>

        <div
          class="select-campaign-template-box text-center"
          v-if="templates?.length > 0"
          v-for="(template, index) in templates"
        >
          <div>
            <div class="content min-h-340">
              {{template?.templateJsonObject?.templateData}}

              <EmailTemplateMobileAndDesktopPreview
                :draggable_editor_data="JSON.parse(template?.templateJsonObject)?.draggable_editor_data"
                :global_style="JSON.parse(template?.templateJsonObject)?.global_style"
                :link_style="JSON.parse(template?.templateJsonObject)?.link_style"
                :wrapperContainerStyle="JSON.parse(template?.templateJsonObject)?.wrapperContainerStyle"
                :templateData="JSON.parse(template?.templateJsonObject)?.templateData"
              />
              <div class="select-template p-10 d-flex">
                <kbutton
                  @click="selectedTemplateNavigation(template)"
                  :class="'h-38 w-100 me-1'"
                  :size="'medium'"
                  :theme-color="'primary'"
                  >Select Template
                </kbutton>
                <kbutton
                  v-if="selectedTemplateType?.id == 3"
                  @click="removeTemplate(template)"
                  type="button"
                  :class="'h-38 min-w-38 ms-1 p-0 hvr-red'"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13 3H10V2C10 1.45 9.55 1 9 1H6C5.45 1 5 1.45 5 2V3H2V5H3V14C3 14.55 3.45 15 4 15H11C11.55 15 12 14.55 12 14V5H13V3ZM6 3C6.00312 2.99688 6.00313 2.00311 6.00003 2H8.99687L9 2.00312V3H6.00313H6ZM4.00312 14H11V5H10V13H9V5H8V13H7V5H6V13H5V5H4V13.9969L4.00312 14Z"
                      fill="#24243A"
                    />
                  </svg>
                </kbutton>
              </div>
            </div>
            <h4 class="font-20 l-height-24 font-w-400 mb-0 mt-3">
              {{generateTemplateName(template?.templateName)}}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </template>
</modal>

<deleteCampaignTemplateModal
  v-if="isRemoveModalOpen"
  :selectedTemplateType="selectedTemplateType"
  @closeDeleteModal="closeDeleteModal"
  :ids="removeModalTemplateId"
>
</deleteCampaignTemplateModal>
