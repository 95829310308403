<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>

<modal
  title="Setup Publishing Strategy"
  :handler="true"
  :cssClass="'c-modal-md content-px-0'"
  :footer="false"
  @modalHandler="onCancel"
>
  <template v-slot:content>
    <div>
      <div class="px-16">
        <div
          class="publising-strategy-tab d-flex justify-content-between mb-24"
        >
          <div
            ref="sendNow"
            @click="publishTypeClickHandler(1)"
            class="cursor-pointer single p-16 mr-10 d-flex justify-content-start align-items-center w-50-p active"
          >
            <div class="icon">
              <span>
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M45.9958 12.3511C45.7738 12.1668 45.5039 12.0493 45.2177 12.0125C44.9315 11.9756 44.6407 12.0208 44.3792 12.1429L13 26.8717V29.7217L26.1799 34.9936L34.6339 48H37.4845L46.4914 13.9042C46.5644 13.6249 46.5566 13.3307 46.4689 13.0557C46.3811 12.7808 46.2171 12.5364 45.9958 12.3511ZM35.7246 45.2817L28.4579 34.1019L39.64 21.8545L37.8707 20.2391L26.6011 32.5819L15.7343 28.2352L43.694 15.1111L35.7246 45.2817Z"
                    fill="#404A61"
                  />
                </svg>
              </span>
            </div>
            <div class="content">
              <h4 class="font-14 mb-1">Send Now</h4>
              <p class="font-12 color-light2 mb-0">
                Start sending emails immediately
              </p>
            </div>
          </div>
          <div
            ref="scheduled"
            @click="publishTypeClickHandler(2)"
            class="cursor-pointer single p-16 ml-10 d-flex justify-content-start align-items-center w-50-p"
          >
            <div class="icon">
              <span>
                <svg
                  width="61"
                  height="60"
                  viewBox="0 0 61 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="30.5" cy="30" r="30" fill="white" />
                  <path
                    d="M30.5 12C40.4411 12 48.5 20.0589 48.5 30C48.5 39.9411 40.4411 48 30.5 48C20.5589 48 12.5 39.9411 12.5 30C12.5 20.0589 20.5589 12 30.5 12ZM30.5 14.25C21.8015 14.25 14.75 21.3015 14.75 30C14.75 38.6985 21.8015 45.75 30.5 45.75C39.1985 45.75 46.25 38.6985 46.25 30C46.25 21.3015 39.1985 14.25 30.5 14.25ZM29.375 18.75C29.9273 18.75 30.3866 19.148 30.4819 19.6728L30.5 19.875V30H36.125C36.7463 30 37.25 30.5037 37.25 31.125C37.25 31.6773 36.852 32.1366 36.3272 32.2319L36.125 32.25H29.375C28.8227 32.25 28.3634 31.852 28.2681 31.3272L28.25 31.125V19.875C28.25 19.2537 28.7537 18.75 29.375 18.75Z"
                    fill="#404A61"
                  />
                </svg>
              </span>
            </div>
            <div class="content">
              <h4 class="font-14 mb-1">Schedule</h4>
              <p class="font-12 color-light2 mb-0">
                Start sending at a specific date and time
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="publishType == 2" class="px-16 pb-24">
        <div
          v-if="prePayload?.campaignType == 1 && publishType == 2"
          class="col-md-12"
        >
          <div class="d-flex justify-content-between mb-3 li-w-50p">
            <radiogroup
              :default-value="dataItems[0].value"
              :data-items="dataItems"
              :layout="'vertical'"
              :class="'list-100-sm'"
              :value="scheduleType"
              @change="handleChange"
            />
          </div>
        </div>
        <div
          v-if="prePayload?.campaignType == 1 && scheduleType == 2"
          class="row"
        >
          <div class="col-md-6">
            <label for="">Batch Size</label>
            <numericTextBox
              v-model="batchSize"
              :format="'p'"
              :min="0.01"
              :max="1"
              :step="0.01"
              :class="'left-align'"
            >
            </numericTextBox>
          </div>
          <div class="col-md-6">
            <label for="">Interval</label>
            <dropdownlist
              :data-items="this.intervalItems"
              :text-field="'text'"
              :data-item-key="'id'"
              :class="'mb-3'"
              :value="this.intervalValue"
              @change="this.handleGenderChangeValue"
            >
            </dropdownlist>
          </div>
        </div>

        <div class="row">
          <!-- <div class="col-md-12">
                        <label for="">Date and Time</label>
                        <datetimepicker :min="new Date()" :default-value="dateTimeValue"
                            :format="'dd MMM yyyy hh:mm:ss a'" :value="dateTimeValue" @change="updateDateAndTime" />
                    </div> -->

          <div class="col-md-12 mb-3">
            <label for="">Time Zone</label>
            <dropdownlist
              :data-items="this.timeZonesList"
              :text-field="'name'"
              :data-item-key="'id'"
              :filterable="true"
              @filterchange="filterChange"
              :value="this.timeZoneValue"
              @change="this.handleTimeZoneChangeValue"
            >
            </dropdownlist>
            <span
              v-if="timeZoneValue?.id===999"
              class="font-14 text-color-2 mt-1 d-flex align-items-center gap-1"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                >
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M10,20A10,10,0,1,0,0,10,10,10,0,0,0,10,20ZM10,5a.935.935,0,0,1,.938.938v4.375a.938.938,0,0,1-1.875,0V5.938A.935.935,0,0,1,10,5ZM8.75,13.75A1.25,1.25,0,1,1,10,15,1.25,1.25,0,0,1,8.75,13.75Z"
                    fill="#ffd43b"
                  />
                </svg>
              </span>
              Choose a time 24 hours ahead of the current time.
            </span>
          </div>

          <div class="col-md-6">
            <label for="">Date</label>

            <datePicker
              :format="'dd-MMM-yyyy'"
              :default-value="scheduleDate"
              @change="updateScaduleDate"
              :min="new Date()"
              :value="scheduleDate"
            >
            </datePicker>
          </div>

          <div class="col-md-6">
            <label for="">Time</label>
            <timePicker
              :class="''"
              :value="scheduleTime"
              @change="handleResumeWorkflowStartTime"
            />
          </div>
        </div>
      </div>
    </div>

    <dialog-actions-bar>
      <div class="d-flex justify-content-end">
        <div class="me-1">
          <kbutton @click="onCancel" :class="'w-100'">Cancel</kbutton>
        </div>
        <div class="ms-1">
          <kbutton
            :theme-color="'primary'"
            @click="onPublishHandler"
            :class="'w-100'"
            >Publish</kbutton
          >
        </div>
      </div>
    </dialog-actions-bar>
  </template>
</modal>
