<div class="dashboard-right-inner">
    <Breadcrumb :id="$route.params.id" />
    <app-loader
        v-if="isLoading || campaignDetailsLoading || isSenderDomainLoading || isGetSenderInfoLoading || isLoader || isUtmLoading"></app-loader>
    <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
    <div v-if="!isFromFlowAutomation"
        class="dashboard-header d-flex flex-wrap justify-content-between align-items-end pb-20">
        <div class="d-flex justify-content-start align-content-center w-100-vsm">
            <h2 class="font-28 font-34-sm-big font-w-300">{{campaignName}}</h2>
            <kbutton :fill-mode="'flat'" :theme-color="'primary'" :icon="'pencil'" @click="nameEditModalHandler"
                ref="campaignNameRef" :class="'color-black hover-none-btn ms-3'">
            </kbutton>
            <Popup :anchor="'campaignNameRef'" :show="nameEditModalVisibility" :popup-class="'advance-filter-popup'">

                <div v-click-outside="onCancelNameEditModal" class="advance-filter-popup-wrap">
                    <div class="head d-flex justify-content-between align-items-center p-16">
                        <h5 class="font-16 m-0">Campaign Name</h5>
                        <kbutton :fill-mode="'flat'" @click="onCancelNameEditModal" :icon="'close'"></kbutton>
                    </div>
                    <div class="p-24">
                        <div>
                            <label class="" for="">Campaign Name</label>
                            <k-input type="text" v-model="campaignNameForEdit" placeholder="Enter Campaign Name">
                            </k-input>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="me-1 w-50-p">
                                <kbutton @click="onCancelNameEditModal" :class="'w-100'">
                                    Cancel
                                </kbutton>
                            </div>
                            <div class="ms-1 w-50-p">
                                <kbutton :disabled="!campaignNameForEdit" @click="onSaveCampaignName"
                                    :theme-color="'primary'" :class="'w-100'">
                                    Save
                                </kbutton>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
        <div class="w-100-vsm d-flex justify-content-between justify-content-sm-end">
            <kbutton :fill-mode="'flat'" :class="'me-3 pl-0-vsm'" @click="onCancelButtonHandler">Cancel & Exit</kbutton>
            <buttongroup>
                <kbutton :disabled="publishButtonApperance() || !isUtmSavable()" :theme-color="'primary'" :size="'medium'"
                    @click="onPublishButtonHandler">
                    Publish
                </kbutton>
                <dropdownbutton :theme-color="'primary'" :class="'ml--2'" :buttonClass="'top-left-radius-0 bottom-left-radius-0'" :popup-settings="popupSettings"
                    :size="'small'" :icon="'arrow-60-down'" :items="submitButtonStatus"
                    @itemclick="saveAsDraftHandler($event, 1)" />
            </buttongroup>
        </div>
    </div>

    <div v-if="isFromFlowAutomation"
        class="dashboard-header d-flex flex-wrap justify-content-between align-items-end py-10 px-16">
        <div class="d-flex justify-content-start align-content-center">
            <kbutton v-if="isFromFlowAutomation" @click="backNavigateToFlowEditor" :fill-mode="'flat'">
                <component :is="'BackPageIcon'"></component>
            </kbutton>
            <h2 v-if="isFromFlowAutomation" class="font-16 font-w-400 mb-0 l-height-38">Configure Email Content</h2>
            <Popup :anchor="'campaignNameRef'" :show="nameEditModalVisibility" :popup-class="'advance-filter-popup'">

                <div v-click-outside="onCancelNameEditModal" class="advance-filter-popup-wrap">
                    <div class="head d-flex justify-content-between align-items-center p-16">
                        <h5 class="font-16 m-0">Campaign Name</h5>
                        <kbutton :fill-mode="'flat'" @click="onCancelNameEditModal" :icon="'close'"></kbutton>
                    </div>
                    <div class="p-24">
                        <div>
                            <label class="" for="">Campaign Name</label>
                            <k-input type="text" v-model="campaignNameForEdit" placeholder="Enter Campaign Name">
                            </k-input>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="me-1 w-50-p">
                                <kbutton @click="onCancelNameEditModal" :class="'w-100'">
                                    Cancel
                                </kbutton>
                            </div>
                            <div class="ms-1 w-50-p">
                                <kbutton :disabled="!campaignNameForEdit" @click="nameVerifyCheckCampaignService"
                                    :theme-color="'primary'" :class="'w-100'">
                                    Save
                                </kbutton>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
        <div>
            <kbutton :fill-mode="'flat'" :class="'me-3'" @click="backNavigateToFlowEditor">Cancel & Exit</kbutton>
            <kbutton v-if="isFromFlowAutomation" :disabled="doneButtonApperance() || !isUtmSavable()" :theme-color="'primary'"
                :size="'medium'" @click="onDoneButtonHandler">
                Done
            </kbutton>
        </div>
    </div>

    <div class="dashboard-content max-height-100-247 overflow-y-auto p-0 h-100 min-h-100vh--150">
        <div class="create-campaign-s2-wrap d-flex justify-content-between min-h-100vh--150">

            <div class="create-campaign-s2-left">
                <div class="h-100 bg-white">
                    <div
                        :class="['create-campaign-s2-content-box py-32 px-24 border-bottom-normal-1', emailInformationEditView ? 'active' : '']">
                        <div class="d-flex">
                            <div class="left">
                                <span :class="['icon', isEmailInformationApproved ? 'active' : '']">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="12" fill="#E9EBEF" />
                                        <path
                                            d="M15.942 8.17773L16.9413 9.177L10.5883 15.5299L7.0589 12.0005L8.05816 11.0013L10.5883 13.5336L15.942 8.17773Z"
                                            fill="#ADB2BD" />
                                    </svg>
                                </span>
                            </div>
                            <div class="right">
                                <div class="d-flex justify-content-between mb-10">
                                    <label for="email" class="font-16 font-w-500 mb-0">Email Information</label>
                                    <div class="d-flex flex-nowrap justify-content-between align-items-center">
                                        <kbutton v-if="!emailInformationEditView"
                                            @click="toggleEmailInformationEditView(true)" :class="'h-38'"
                                            :size="'medium'" :theme-color="'primary'" :fill-mode="'outline'">
                                            Edit
                                        </kbutton>
                                        <kbutton v-if="emailInformationEditView" :fill-mode="'flat'"
                                            @click="toggleEmailInformationEditView(false)" :class="'me-2'">Cancel
                                        </kbutton>
                                        <kbutton @click="onSaveEmailInformation" v-if="emailInformationEditView"
                                            :class="'h-38'" :size="'medium'" :icon="''" :theme-color="'primary'">Save
                                        </kbutton>
                                    </div>
                                </div>
                                <div v-if="!emailInformationEditView">
                                    <div>

                                        <div v-if="campaignSubject?.length <= 1">
                                            <h6 :class="['font-16', !isEmailInformationApproved ? 'opacity-5' : '']">
                                                Subject Line</h6>
                                            <p :class="['font-16', !isEmailInformationApproved ? 'opacity-5' : '']">
                                                {{campaignSubject[0]?.subjectLine || 'No subject line defined'}}
                                            </p>
                                            <hr class="mt-8 mb-24">
                                        </div>

                                        <div v-if="campaignSubject?.length > 1 && !isCampaignSubjectLineViewMore">
                                            <h6 :class="['font-16', !isEmailInformationApproved ? 'opacity-5' : '']">
                                                Subject Line</h6>
                                            <div class="d-flex justify-content-between">
                                                <p :class="['font-16', !isEmailInformationApproved ? 'opacity-5' : '']">
                                                    {{campaignSubject[0]?.subjectLine || 'No subject line defined'}}
                                                </p>
                                                <kbutton @click="toggleSubjectLineViewMore" :fill-mode="'flat'"
                                                    :class="'h-38'" :size="'medium'" :icon="''"
                                                    :theme-color="'primary'">View more
                                                </kbutton>
                                            </div>
                                            <hr class="mt-8 mb-24">
                                        </div>

                                        <div v-if="campaignSubject?.length > 1  && isCampaignSubjectLineViewMore"
                                            v-for="(item, index) in campaignSubject">
                                            <h6 :class="['font-16', !isEmailInformationApproved ? 'opacity-5' : '']">
                                                Subject Line</h6>
                                            <div class="d-flex justify-content-between">
                                                <p :class="['font-16', !isEmailInformationApproved ? 'opacity-5' : '']">
                                                    {{item.subjectLine || 'No subject line defined'}}
                                                </p>
                                                <kbutton v-if="index == 0" @click="toggleSubjectLineViewMore"
                                                    :fill-mode="'flat'" :class="'h-38'" :size="'medium'" :icon="''"
                                                    :theme-color="'primary'">
                                                    View less
                                                </kbutton>
                                            </div>
                                            <hr>
                                        </div>
                                        <h6 :class="['font-16', !previewText ? 'opacity-5' : '']">Preview
                                            Text</h6>
                                        <p :class="['font-16', !previewText ? 'opacity-5' : '']">
                                            {{previewText || 'No preview text defined'}}</p>
                                    </div>
                                </div>
                                <div v-if="emailInformationEditView">

                                    <div class="row" v-for="(item, index) in subjectLinesForEdit" :key="item">
                                        <div class="campaign-create-input-wrap">
                                            <label class="font-16">Subject Line *</label>
                                            <div class="d-flex flex-nowrap w-100">
                                                <SubjectLineComponent :index="index" :onSync="onSyncSubjectLines"
                                                    :value="item?.subjectLine" />
                                                <kbutton v-if="subjectLinesForEdit?.length > 1"
                                                    @click="subjectLineCloseHandler(index)"
                                                    :class="'h-38 min-w-38 ms-1 ms-sm-2 ms-md-3'" :icon="'close'">
                                                </kbutton>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div v-if="campaignType == 1" class="campaign-create-input-wrap">
                                        <label class="font-16">Subject Line *</label>
                                        <SubjectLineComponent :onSync="onSyncSubjectLine" :value="subjectLineForEdit" />
                                    </div> -->

                                    <kbutton v-if="campaignType == 2" @click="addSubjectLineFieldHanlder"
                                        :class="'h-38 mb-16'" :size="'medium'" :icon="''" :theme-color="'primary'"
                                        :fill-mode="'outline'">+
                                        Add Subject Line</kbutton>

                                    <div class="campaign-create-input-wrap">
                                        <label class="font-16">Preview Text</label>
                                        <SubjectLineComponent placeHolder="No preview text defined"
                                            :onSync="onSyncPreviewText" :value="previewTextForEdit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        :class="['create-campaign-s2-content-box py-32 px-24 border-bottom-normal-1', senderInformationEditView  ? 'active' : '']">
                        <div class="d-flex">
                            <div class="left">
                                <span :class="['icon',senderEmail ? 'active' : '']">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="12" fill="#E9EBEF" />
                                        <path
                                            d="M15.942 8.17773L16.9413 9.177L10.5883 15.5299L7.0589 12.0005L8.05816 11.0013L10.5883 13.5336L15.942 8.17773Z"
                                            fill="#ADB2BD" />
                                    </svg>
                                </span>
                            </div>
                            <div class="right">
                                <div class="d-flex justify-content-between mb-10">
                                    <label for="email" class="font-16 font-w-500 mb-0">Sender Information</label>
                                    <div class="d-flex flex-nowrap justify-content-between align-items-center">
                                        <kbutton v-if="!senderInformationEditView"
                                            @click="toggleSenderInformationEditView(true)" :class="'h-38'"
                                            :size="'medium'" :theme-color="'primary'" :fill-mode="'outline'">Edit
                                        </kbutton>
                                        <kbutton v-if="senderInformationEditView" :fill-mode="'flat'"
                                            @click="toggleSenderInformationEditView(false)" :class="'me-2'">Cancel
                                        </kbutton>
                                        <kbutton :disabled="!senderEmailForEdit || !senderNameForEdit"
                                            @click="onSaveSenderInformation" v-if="senderInformationEditView"
                                            :class="'h-38'" :size="'medium'" :icon="''" :theme-color="'primary'">Save
                                        </kbutton>
                                    </div>
                                </div>
                                <div v-if="!senderInformationEditView">
                                    <div>
                                        <h6 class="font-16">Sender Name</h6>
                                        <p class="font-16">{{senderName}}</p>
                                        <hr class="mt-8 mb-24">
                                        <h6 class="font-16">Sender Email Address</h6>
                                        <p class="font-16">{{senderEmail}}</p>
                                    </div>
                                </div>
                                <div v-if="senderInformationEditView">
                                    <div class="row">
                                        <div class="col-xxl-6 col-xl-1550-12">
                                            <div class="campaign-create-input-wrap">
                                                <label class="font-16">Sender Name *</label>
                                                <k-input type="text" v-model="senderNameForEdit"
                                                    placeholder="Sydney Tycoons">
                                                </k-input>
                                            </div>
                                        </div>
                                        <div class="col-xxl-6 col-xl-1550-12">
                                            <div class="campaign-create-input-wrap">
                                                <label class="font-16">Sender Email Address *</label>
                                                <k-input type="text" v-model="senderEmailForEdit"
                                                    @keypress="isDomainVerified = false"
                                                    @input="isDomainVerified = false" :class="'mb-1'"
                                                    placeholder="adam.smith@gmail.com">
                                                </k-input>
                                                <p class="font-12 l-height-18 mb-3" v-if="isDomainVerified">
                                                    {{senderEmailForEdit?.split("@")[1]}} must
                                                    be
                                                    verified
                                                    before
                                                    you can send from this address.</p>
                                                <kbutton v-if="isDomainVerified" @click="toggleDomainNameVerifyModal()"
                                                    :class="'h-38'" :size="'medium'" :theme-color="'primary'"
                                                    :fill-mode="'outline'">
                                                    Verify Domain
                                                </kbutton>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!isFromFlowAutomation"
                        :class="['create-campaign-s2-content-box py-32 px-24 border-bottom-normal-1', recipientsInformationEditView ? 'active' : '']">
                        <div class="d-flex">
                            <div class="left">
                                <span
                                    :class="['icon', selectedLists?.length || selectedSegments?.length ? 'active' : '']">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="12" fill="#E9EBEF" />
                                        <path
                                            d="M15.942 8.17773L16.9413 9.177L10.5883 15.5299L7.0589 12.0005L8.05816 11.0013L10.5883 13.5336L15.942 8.17773Z"
                                            fill="#ADB2BD" />
                                    </svg>
                                </span>
                            </div>
                            <div class="right">
                                <div class="d-flex justify-content-between mb-10">
                                    <label for="email" class="font-16 font-w-500 mb-0">Recipients</label>
                                    <div class="d-flex flex-nowrap justify-content-between align-items-center">
                                        <kbutton v-if="!recipientsInformationEditView"
                                            @click="toggleRecipientsInformationEditView(true)" :class="'h-38'"
                                            :size="'medium'" :theme-color="'primary'" :fill-mode="'outline'">Edit
                                        </kbutton>
                                        <kbutton v-if="recipientsInformationEditView" :fill-mode="'flat'"
                                            @click="toggleRecipientsInformationEditView(false)" :class="'me-2'">Cancel
                                        </kbutton>
                                        <kbutton @click="onSaveRecipients" v-if="recipientsInformationEditView"
                                            :class="'h-38'" :size="'medium'" :icon="''" :theme-color="'primary'">Save
                                        </kbutton>
                                    </div>
                                </div>
                                <div v-if="!recipientsInformationEditView">
                                    <div>
                                        <h6 v-if="selectedLists?.length === 0 && selectedSegments?.length === 0 && selectedListsForDontSend?.length === 0 && selectedSegmentsForDontSend?.length === 0"
                                            class="font-16 opacity-5">No recipients selected
                                            yet.</h6>
                                        <div v-else>
                                            <div v-if="selectedLists?.length != 0 || selectedSegments?.length != 0 "
                                                class="d-flex flex-wrap align-items-center">
                                                <h6 class="font-16 me-2">Send To</h6>
                                                <div class="d-flex flex-wrap align-content-center"
                                                    v-for="(list, index) in selectedLists">
                                                    <span class="my-campaign-recipients">{{list?.audienceListName}}
                                                        (List)
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-wrap align-content-center"
                                                    v-for="(segment, index) in selectedSegments">
                                                    <span class="my-campaign-recipients">{{segment?.segmentName}}
                                                        (Segment)
                                                    </span>
                                                </div>
                                            </div>

                                            <hr class="mt-8 mb-24">

                                            <div v-if="selectedListsForDontSend?.length != 0 || selectedSegmentsForDontSend?.length != 0 "
                                                class="d-flex flex-wrap align-items-center">

                                                <h6 class="font-16 me-2">Don't Send To</h6>
                                                <div class="d-flex flex-wrap align-content-center"
                                                    v-for="(list, index) in selectedListsForDontSend">
                                                    <span class="my-campaign-recipients">{{list?.audienceListName}}
                                                        (List)
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-wrap align-content-center"
                                                    v-for="(segment, index) in selectedSegmentsForDontSend">
                                                    <span class="my-campaign-recipients">{{segment?.segmentName}}
                                                        (Segment)
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="recipientsInformationEditView">
                                    <div class="button-group">
                                        <h6 class="font-16">Send To</h6>

                                        <kbutton @click="togleAudienceListModal('sendTo')" :class="'h-38 me-3 mb-3'"
                                            :size="'medium'" :theme-color="'primary'" :fill-mode="'outline'">+ Add Lists
                                        </kbutton>
                                        <kbutton @click="toggleSegmentListModal('sendTo')" :class="'h-38 me-3 mb-3'"
                                            :size="'medium'" :theme-color="'primary'" :fill-mode="'outline'">+ Add
                                            Segments</kbutton>
                                        <div class="d-flex flex-wrap align-content-center">
                                            <div class="d-flex flex-wrap align-content-center"
                                                v-for="(list, index) in selectedListsForEdit">
                                                <span class="my-campaign-recipients">{{list?.audienceListName}} (List)
                                                    <kbutton
                                                        @click="onRemoveList(list?.audienceListId, 'selectedListsForEdit')"
                                                        :class="'p-0 hover-none-btn s-14 ms-2 color-black'"
                                                        :size="'medium'" :theme-color="'primary'" :fill-mode="'flat'"
                                                        :icon="'close-circle'">
                                                    </kbutton>
                                                </span>
                                            </div>
                                            <div class="d-flex flex-wrap align-content-center"
                                                v-for="(segment, index) in selectedSegmentsForEdit">
                                                <span class="my-campaign-recipients">{{segment?.segmentName}} (Segment)
                                                    <kbutton
                                                        @click="onRemoveSegment(segment?.segmentId, 'selectedSegmentsForEdit')"
                                                        :class="'p-0 hover-none-btn s-14 ms-2 color-black'"
                                                        :size="'medium'" :theme-color="'primary'" :fill-mode="'flat'"
                                                        :icon="'close-circle'">
                                                    </kbutton>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="mt-8 mb-24">
                                    <div class="button-group">
                                        <h6 class="font-16">Don't Send To</h6>

                                        <kbutton @click="togleAudienceListModal('doNotsendTo')"
                                            :class="'h-38 me-3 mb-3'" :size="'medium'" :theme-color="'primary'"
                                            :fill-mode="'outline'">+ Add Lists
                                        </kbutton>
                                        <kbutton @click="toggleSegmentListModal('doNotsendTo')"
                                            :class="'h-38 me-3 mb-3'" :size="'medium'" :theme-color="'primary'"
                                            :fill-mode="'outline'">+ Add
                                            Segments</kbutton>
                                        <div class="d-flex flex-wrap align-content-center">
                                            <div class="d-flex flex-wrap align-content-center"
                                                v-for="(list, index) in selectedListsForDontSendForEdit">
                                                <span class="my-campaign-recipients">{{list?.audienceListName}} (List)
                                                    <kbutton
                                                        @click="onRemoveList(list?.audienceListId,'selectedListsForDontSendForEdit')"
                                                        :class="'p-0 hover-none-btn s-14 ms-2 color-black'"
                                                        :size="'medium'" :theme-color="'primary'" :fill-mode="'flat'"
                                                        :icon="'close-circle'">
                                                    </kbutton>
                                                </span>
                                            </div>
                                            <div class="d-flex flex-wrap align-content-center"
                                                v-for="(segment, index) in selectedSegmentsForDontSendForEdit">
                                                <span class="my-campaign-recipients">{{segment?.segmentName}} (Segment)
                                                    <kbutton
                                                        @click="onRemoveSegment(segment?.segmentId, 'selectedSegmentsForDontSendForEdit')"
                                                        :class="'p-0 hover-none-btn s-14 ms-2 color-black'"
                                                        :size="'medium'" :theme-color="'primary'" :fill-mode="'flat'"
                                                        :icon="'close-circle'">
                                                    </kbutton>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="campaignType == 2"
                        :class="['create-campaign-s2-content-box py-32 px-24 border-bottom-normal-1', advancedOptimizationEditView ? 'active' : '']"
                        b>
                        <div class="d-flex">
                            <div class="left">
                                <span :class="['icon', isOptimizationSettingApproved ? 'active' : '']">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="12" fill="#E9EBEF"></circle>
                                        <path
                                            d="M15.942 8.17773L16.9413 9.177L10.5883 15.5299L7.0589 12.0005L8.05816 11.0013L10.5883 13.5336L15.942 8.17773Z"
                                            fill="#ADB2BD"></path>
                                    </svg>
                                </span>
                            </div>
                            <div class="right">
                                <div class="d-flex justify-content-between mb-10">
                                    <label for="email" class="font-16 font-w-500 mb-0">Advanced Optimization Settings</label>
                                    <div class="d-flex flex-nowrap justify-content-between align-items-center">
                                        <kbutton v-if="!advancedOptimizationEditView"
                                            @click="toggleAdvancedOptimizationEditView(true)" :class="'h-38'"
                                            :size="'medium'" :theme-color="'primary'" :fill-mode="'outline'">Edit
                                        </kbutton>
                                        <kbutton @click="toggleAdvancedOptimizationEditView(false)"
                                            v-if="advancedOptimizationEditView" :fill-mode="'flat'" :class="'me-2'">
                                            Cancel
                                        </kbutton>
                                        <kbutton @click="onSaveAdvancedOptimization"
                                            :disabled="advanceOptimizationSavebuttonVisibility()"
                                            v-if="advancedOptimizationEditView" :class="'h-38'" :size="'medium'"
                                            :icon="''" :theme-color="'primary'">Save
                                        </kbutton>
                                    </div>
                                </div>
                                <div>
                                    <div v-if="!advancedOptimizationEditView && campaignOptimizationType == 0">
                                        <h6 class="font-16 opacity-5">Optimization strategy not defined yet.</h6>
                                    </div>

                                    <div v-if="!advancedOptimizationEditView && campaignOptimizationType == 1">
                                        <h6 class="font-16"><b>Testing Strategy:</b> Campaign will be tested
                                            on
                                            {{testEmailsPerCombination}} emails per combination for
                                            {{testDurationValue?.text}}
                                            to determine the best performing combination based on
                                            {{testMetricValue?.text}}.
                                            Best performing combination
                                            will be sent to the rest of the recipients who aren’t part of the test
                                            phase.</h6>
                                    </div>

                                    <div v-if="!advancedOptimizationEditView && campaignOptimizationType == 2">
                                        <h6 class="font-16"><b>Testing Strategy:</b> Campaign will be tested
                                            on
                                            {{(testPopulation*100).toFixed()}}% of
                                            recipients for {{testDurationValue?.text}} to determine the best performing
                                            combination based on {{testMetricValue?.text}}. Best
                                            performing combination will be sent to the rest of the recipients who aren’t
                                            part of
                                            the test phase.

                                            .</h6>
                                    </div>

                                    <div v-if="advancedOptimizationEditView">
                                        <div>
                                            <div class="d-flex justify-content-between mb-3">
                                                <radiogroup :class="'gap-90'"
                                                    :default-value="optimizationSettings[0].value"
                                                    :data-items="optimizationSettings" :layout="'horizontal'"
                                                    :value="advancedOptimizationTypeForEdit"
                                                    @change="handleAdvancedOptimizationType" />
                                            </div>
                                            <div class="automated-optimization w-100">
                                                <div class="d-flex flex-wrap">
                                                    <div class="col-xxl-6 col-xl-12 pe-3">
                                                        <div
                                                            class="d-flex justify-content-between align-items-center mb-24 w-100">
                                                            <label class="mb-0 me-3 mb-2 mb-sm-0">Test duration</label>
                                                            <dropdownlist :data-items="this.testDurationItems"
                                                                :text-field="'text'" :data-item-key="'id'"
                                                                :value="this.testDurationValueForEdit"
                                                                @change="this.handleTestDurationValue"
                                                                :style="{ width: 'auto' , minWidth: '118px' }">
                                                            </dropdownlist>
                                                        </div>
                                                    </div>
                                                    <div class="col-xxl-6 col-xl-12 pe-3">
                                                        <div
                                                            class="d-flex justify-content-between align-items-center mb-24 w-100">
                                                            <label class="mb-0 me-3 mb-2 mb-sm-0">Select test
                                                                metric</label>
                                                            <dropdownlist :data-items="this.testMetricItems"
                                                                :text-field="'text'" :data-item-key="'id'"
                                                                :value="this.testMetricValueForEdit"
                                                                @change="this.handleTestMetricValue"
                                                                :style="{ width: '200px' , minWidth: '118px' }">
                                                            </dropdownlist>
                                                        </div>
                                                    </div>
                                                    <div v-if="advancedOptimizationTypeForEdit == 1"
                                                        class="col-xxl-6 col-xl-12 pe-3">
                                                        <div
                                                            class="d-flex justify-content-between align-items-center mb-24 w-100">
                                                            <label class="mb-0 me-3 mb-2 mb-sm-0">Test emails per
                                                                combination</label>
                                                            <numericTextBox :class="'h-38 mb-0'"
                                                                v-model="testEmailsPerCombinationForEdit" :min="1"
                                                                :max="100" :step="1"
                                                                :style="{ width: '118px' , minWidth: '118px' }">
                                                            </numericTextBox>
                                                        </div>
                                                    </div>
                                                    <div v-if="advancedOptimizationTypeForEdit == 2"
                                                        class="col-xxl-6 col-xl-12 pe-3">
                                                        <div
                                                            class="d-flex justify-content-between align-items-center mb-24 w-100">
                                                            <label class="mb-0 me-3 mb-2 mb-sm-0">Select test
                                                                population</label>
                                                            <numericTextBox v-model="testPopulationForEdit"
                                                                :class="'h-38 mb-0'" :format="'p'" :min="0.01" :max="1"
                                                                :step="0.01"
                                                                :style="{ width: '118px' , minWidth: '118px' }">
                                                            </numericTextBox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p v-if="advancedOptimizationTypeForEdit == 1" class="font-16">The best
                                                    performing
                                                    combination will be selected based on the
                                                    performance of test emails for each combination.</p>
                                                <p v-if="advancedOptimizationTypeForEdit == 2" class="font-16">The best
                                                    performing
                                                    combination will be selected based on the
                                                    performance of each combination from the test population.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="create-campaign-s2-content-box py-32 px-24 border-bottom-normal-1">
                        <div class="d-flex">
                            <div class="left">
                                <span :class="['icon', linkTrackingDetails.isLinkTrakingEnabled && isUtmSavable() ? 'active' : '']">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#E9EBEF"></circle><path d="M15.942 8.17773L16.9413 9.177L10.5883 15.5299L7.0589 12.0005L8.05816 11.0013L10.5883 13.5336L15.942 8.17773Z" fill="#ADB2BD"></path></svg>
                                </span>
                            </div>
                            <div class="right">
                                <div class="d-flex justify-content-between mb-10">
                                    <label for="email" class="font-16 font-w-500 mb-0">Link Tracking</label>
                                    <div class="d-flex flex-nowrap justify-content-between align-items-center">
                                    </div>
                                </div>
                                <div class="border-bottom-normal-1 d-flex py-14">
                                    <div class="w-42">
                                        <k-switch v-model="linkTrackingDetails.isLinkTrakingEnabled" :class="'w-34 h-18 switch-34-18'" :size="'small'" />
                                    </div>
                                    <p class="font-14 font-w-500 mb-0">Include tracking parameters</p>
                                </div>
                                <div v-if="linkTrackingDetails.isLinkTrakingEnabled" class="d-flex py-14">
                                    <div class="w-42">
                                        <k-switch @change="customUtmValidation" v-model="linkTrackingDetails.isCustomizedLinkTrakingEnabled" :class="'w-34 h-18 switch-34-18'" :size="'small'" />
                                    </div>
                                    <p class="font-14 font-w-500 mb-0">Customise tracking parameters</p>
                                </div>
                                <div v-if="linkTrackingDetails.isCustomizedLinkTrakingEnabled && linkTrackingDetails.isLinkTrakingEnabled" class="custom-table-wrap">
                                    <div v-if="this.linkTrackingDetails.campaignUtmList?.length" class="custom-table th-td-checkbox-style-2 overflow-hidden">
                                        <table width="100%">
                                            <thead>
                                                <tr>
                                                    <th style="width: 60px">
                                                        <checkbox v-model="isAllUtmChecked" @change="allUtmCheckHandler" :class="'font-16 font-w-500 cursor-pointer checkbox-style-2 position-relative'" :label="''" />
                                                    </th>
                                                    <th>UTM parameter</th>
                                                    <th>{{isFromFlowAutomation ? 'Flow' : 'Campaign'}} value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in this.linkTrackingDetails.campaignUtmList" :key="item?.id">
                                                    <td style="width: 60px">
                                                        <checkbox :disabled="[0, 1]?.includes(index) ? true : false" v-model="item.isActive" @change="utmCheckHandler" :class="'font-16 font-w-500 cursor-pointer checkbox-style-2 position-relative'" :label="''" />
                                                    </td>
                                                    <td>{{item?.utmLabel}} <span v-if="[0, 1]?.includes(index)" class="red">*</span></td>
                                                    <td>
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <div class="position-relative w-100">
                                                                <dropdownlist 
                                                                :class="'w-100-vsm'" 
                                                                :style="{ width: '100%' }" 
                                                                :data-items="this.utmDropdownvalues"
                                                                :text-field="'utmFieldLabel'"
                                                                :data-item-key="'id'"
                                                                :group-field="'groupTitle'"
                                                                :value-field="'utmFieldName'"
                                                                :value-primitive="true"
                                                                @change="() => customUtmValidation()"
                                                                v-model="item.utmValue"
                                                                />
                                                                <span class="k-form-error font-10-vsm position-absolute mt--2">{{this.utmErrors[item?.utmKey]}}</span>
                                                            </div>
                                                            <div class="w-50 ps-2 text-right">
                                                                <kbutton v-if="item?.utmFieldId == 6" @click="deleteUTmField(index)" :fill-mode="'flat'" :theme-color="'primary'" :icon="'close'" :class="'color-black p-0 close-button-tr'"></kbutton>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <kbutton :class="'h-38 mt-20'" @click="() => handleUtmParameterModal(true)" :size="'medium'" :theme-color="'primary'" :fill-mode="'outline'">Add UTM Parameter</kbutton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="create-campaign-s2-right">
                <div class="h-100 bg-white">
                    <div class="d-flex justify-content-center align-items-center h-100 text-center"
                        v-if="!isEmailTemplateSelected">
                        <div class="p-44">
                            <div class="banner">
                                <svg width="216" height="205" viewBox="0 0 216 205" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="102.496" cy="102.5" r="102.496" fill="white" />
                                    <circle cx="102.496" cy="102.5" r="102.496" fill="#0052CC" fill-opacity="0.12" />
                                    <g filter="url(#filter0_dd_5732_63389)">
                                        <rect x="23.6531" y="21.9048" width="159.438" height="160.314" rx="18"
                                            fill="white" />
                                    </g>
                                    <g opacity="0.9" filter="url(#filter1_d_5732_63389)">
                                        <rect x="7.00842" y="30.0039" width="162.066" height="39.4215" rx="10"
                                            fill="white" />
                                    </g>
                                    <rect x="11.2648" y="34.0039" width="30.6612" height="31.5372" rx="8"
                                        fill="#A4C3F3" />
                                    <rect x="50.8101" y="40.3013" width="91.1074" height="7.8843" rx="3.94215"
                                        fill="#0052CC" fill-opacity="0.25" />
                                    <rect x="51.9996" y="53.0039" width="47" height="7" rx="3.5" fill="white" />
                                    <rect x="51.9996" y="53.0039" width="47" height="7" rx="3.5" fill="#0052CC"
                                        fill-opacity="0.12" />
                                    <path
                                        d="M17.069 44.3251C17.069 43.3082 17.4729 42.3329 18.192 41.6138C18.9111 40.8947 19.8864 40.4907 20.9033 40.4907H32.4065C33.4234 40.4907 34.3987 40.8947 35.1178 41.6138C35.8369 42.3329 36.2409 43.3082 36.2409 44.3251V55.8282C36.2409 56.8452 35.8369 57.8205 35.1178 58.5395C34.3987 59.2586 33.4234 59.6626 32.4065 59.6626H20.9033C19.8864 59.6626 18.9111 59.2586 18.192 58.5395C17.4729 57.8205 17.069 56.8452 17.069 55.8282V44.3251Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M23.2998 49.1182C24.6233 49.1182 25.6963 48.0452 25.6963 46.7217C25.6963 45.3981 24.6233 44.3252 23.2998 44.3252C21.9763 44.3252 20.9033 45.3981 20.9033 46.7217C20.9033 48.0452 21.9763 49.1182 23.2998 49.1182Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M29.0763 50.6718L20.9033 59.6625H32.5339C33.5171 59.6625 34.4599 59.2719 35.1551 58.5768C35.8503 57.8816 36.2408 56.9387 36.2408 55.9556V55.8281C36.2408 55.3814 36.0731 55.2098 35.7711 54.8791L31.908 50.6661C31.7279 50.4697 31.5089 50.313 31.2649 50.2059C31.0209 50.0989 30.7573 50.0439 30.4909 50.0444C30.2244 50.045 29.961 50.101 29.7175 50.2091C29.4739 50.3171 29.2555 50.4747 29.0763 50.6718V50.6718Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <g opacity="0.9" filter="url(#filter2_d_5732_63389)">
                                        <rect x="7.00842" y="134.913" width="162.066" height="39.4215" rx="10"
                                            fill="white" />
                                    </g>
                                    <rect x="11.2648" y="139.293" width="30.6612" height="30.6612" rx="8"
                                        fill="#A4C3F3" />
                                    <rect opacity="0.5" x="50.9996" y="145.004" width="29" height="7" rx="3.5"
                                        fill="#78A6ED" />
                                    <rect x="50.8101" y="156.814" width="78.843" height="7.8843" rx="3.94215"
                                        fill="white" />
                                    <rect x="50.8101" y="156.814" width="78.843" height="7.8843" rx="3.94215"
                                        fill="#0052CC" fill-opacity="0.12" />
                                    <path
                                        d="M17.069 149.033C17.069 148.016 17.4729 147.041 18.192 146.322C18.9111 145.603 19.8864 145.199 20.9033 145.199H32.4065C33.4234 145.199 34.3987 145.603 35.1178 146.322C35.8369 147.041 36.2409 148.016 36.2409 149.033V160.536C36.2409 161.553 35.8369 162.528 35.1178 163.248C34.3987 163.967 33.4234 164.371 32.4065 164.371H20.9033C19.8864 164.371 18.9111 163.967 18.192 163.248C17.4729 162.528 17.069 161.553 17.069 160.536V149.033Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M23.2998 153.826C24.6233 153.826 25.6963 152.753 25.6963 151.43C25.6963 150.106 24.6233 149.033 23.2998 149.033C21.9763 149.033 20.9033 150.106 20.9033 151.43C20.9033 152.753 21.9763 153.826 23.2998 153.826Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M29.0763 155.38L20.9033 164.37H32.5339C33.5171 164.37 34.4599 163.98 35.1551 163.285C35.8503 162.59 36.2408 161.647 36.2408 160.664V160.536C36.2408 160.089 36.0731 159.918 35.7711 159.587L31.908 155.374C31.7279 155.178 31.5089 155.021 31.2649 154.914C31.0209 154.807 30.7573 154.752 30.4909 154.752C30.2244 154.753 29.961 154.809 29.7175 154.917C29.4739 155.025 29.2555 155.183 29.0763 155.38V155.38Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <g filter="url(#filter3_d_5732_63389)">
                                        <rect x="49.9341" y="83.2271" width="162.066" height="39.4215" rx="10"
                                            fill="white" />
                                        <rect x="50.4341" y="83.7271" width="161.066" height="38.4215" rx="9.5"
                                            stroke="#0052CC" stroke-opacity="0.5" />
                                    </g>
                                    <rect x="54.1901" y="87.6074" width="30.6612" height="30.6612" rx="8"
                                        fill="url(#paint0_linear_5732_63389)" />
                                    <rect x="92.8596" y="93.7393" width="91.9835" height="7.00826" rx="3.50413"
                                        fill="#0052CC" fill-opacity="0.25" />
                                    <rect x="92.9996" y="105.004" width="44" height="8" rx="4" fill="white" />
                                    <rect x="92.9996" y="105.004" width="44" height="8" rx="4" fill="#0052CC"
                                        fill-opacity="0.12" />
                                    <path
                                        d="M59.8375 97.4164C59.8375 96.3995 60.2415 95.4242 60.9606 94.7051C61.6797 93.986 62.655 93.582 63.6719 93.582H75.175C76.192 93.582 77.1673 93.986 77.8863 94.7051C78.6054 95.4242 79.0094 96.3995 79.0094 97.4164V108.92C79.0094 109.936 78.6054 110.912 77.8863 111.631C77.1673 112.35 76.192 112.754 75.175 112.754H63.6719C62.655 112.754 61.6797 112.35 60.9606 111.631C60.2415 110.912 59.8375 109.936 59.8375 108.92V97.4164Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M66.0684 102.209C67.3919 102.209 68.4648 101.137 68.4648 99.813C68.4648 98.4894 67.3919 97.4165 66.0684 97.4165C64.7448 97.4165 63.6719 98.4894 63.6719 99.813C63.6719 101.137 64.7448 102.209 66.0684 102.209Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M71.8449 103.763L63.6719 112.754H75.3025C76.2856 112.754 77.2285 112.363 77.9237 111.668C78.6188 110.973 79.0094 110.03 79.0094 109.047V108.919C79.0094 108.473 78.8416 108.301 78.5397 107.97L74.6765 103.757C74.4965 103.561 74.2775 103.404 74.0335 103.297C73.7895 103.19 73.5259 103.135 73.2594 103.136C72.993 103.136 72.7296 103.192 72.486 103.3C72.2425 103.408 72.0241 103.566 71.8449 103.763V103.763Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M79.2849 137.892L74.8802 121.318C74.4868 119.837 75.8353 118.482 77.3175 118.869L94.0552 123.23C95.5552 123.621 96.0596 125.496 94.9581 126.587L93.3337 128.195C92.5434 128.978 92.5434 130.255 93.3337 131.038L99.7215 137.363C100.512 138.146 100.512 139.423 99.7215 140.205L97.0091 142.891C96.2296 143.663 94.974 143.663 94.1945 142.891L87.751 136.511C86.9716 135.739 85.7159 135.739 84.9365 136.511L82.6251 138.8C81.5329 139.881 79.6797 139.378 79.2849 137.892Z"
                                        fill="white" stroke="#0052CC" stroke-width="1.5" />
                                    <defs>
                                        <filter id="filter0_dd_5732_63389" x="18.6531" y="19.9048" width="169.438"
                                            height="171.314" filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2.5" />
                                            <feColorMatrix type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                result="effect1_dropShadow_5732_63389" />
                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="2" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feColorMatrix type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                                            <feBlend mode="normal" in2="effect1_dropShadow_5732_63389"
                                                result="effect2_dropShadow_5732_63389" />
                                            <feBlend mode="normal" in="SourceGraphic"
                                                in2="effect2_dropShadow_5732_63389" result="shape" />
                                        </filter>
                                        <filter id="filter1_d_5732_63389" x="3.00842" y="29.0039" width="170.066"
                                            height="47.4214" filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feMorphology radius="1" operator="erode" in="SourceAlpha"
                                                result="effect1_dropShadow_5732_63389" />
                                            <feOffset dy="3" />
                                            <feGaussianBlur stdDeviation="2.5" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                result="effect1_dropShadow_5732_63389" />
                                            <feBlend mode="normal" in="SourceGraphic"
                                                in2="effect1_dropShadow_5732_63389" result="shape" />
                                        </filter>
                                        <filter id="filter2_d_5732_63389" x="3.00842" y="133.913" width="170.066"
                                            height="47.4214" filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feMorphology radius="1" operator="erode" in="SourceAlpha"
                                                result="effect1_dropShadow_5732_63389" />
                                            <feOffset dy="3" />
                                            <feGaussianBlur stdDeviation="2.5" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                result="effect1_dropShadow_5732_63389" />
                                            <feBlend mode="normal" in="SourceGraphic"
                                                in2="effect1_dropShadow_5732_63389" result="shape" />
                                        </filter>
                                        <filter id="filter3_d_5732_63389" x="45.9341" y="82.2271" width="170.066"
                                            height="47.4214" filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feMorphology radius="1" operator="erode" in="SourceAlpha"
                                                result="effect1_dropShadow_5732_63389" />
                                            <feOffset dy="3" />
                                            <feGaussianBlur stdDeviation="2.5" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                result="effect1_dropShadow_5732_63389" />
                                            <feBlend mode="normal" in="SourceGraphic"
                                                in2="effect1_dropShadow_5732_63389" result="shape" />
                                        </filter>
                                        <linearGradient id="paint0_linear_5732_63389" x1="69.5206" y1="87.6074"
                                            x2="80.9091" y2="118.269" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#0052CC" />
                                            <stop offset="1" stop-color="#A4C3F3" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div class="mt-4">
                                <kbutton @click="openSelectTemplateModal" :class="'h-38 min-w-312 min-w-auto-md'"
                                    :size="'medium'" :icon="'palette'" :theme-color="'primary'">
                                    Design Email Content
                                </kbutton>
                            </div>
                            <!-- <div class="mt-3">
                                <kbutton :class="'h-38 min-w-312'" :size="'medium'" :icon="'html'"
                                    :theme-color="'primary'" :fill-mode="'outline'">
                                    Create with HTML
                                </kbutton>
                            </div> -->
                        </div>
                    </div>
                    <div class="bg-white h-100" v-if="isEmailTemplateSelected">
                        <div
                            class="bg-white create-campaign-tab-wrap position-relative d-flex justify-content-between align-items-center p-8">
                            <div class="create-campaign-tab-nav d-flex justify-content-start align-items-center">
                                <kbutton @click="templateViewToggle('desktop')" :fill-mode="'none'"
                                    :class="templatePreviewType == 'desktop' ? 'active' : ''">
                                    Desktop
                                </kbutton>
                                <kbutton @click="templateViewToggle('mobile')" :fill-mode="'none'"
                                    :class="templatePreviewType == 'mobile' ? 'active' : ''">
                                    Mobile</kbutton>
                            </div>
                            <kbutton v-if="emailTemplateId" @click="editTemplateHandler" :fill-mode="'flat'"
                                :class="'ms-3 color-black'" :theme-color="'primary'" :icon="'pencil'">
                                Edit
                            </kbutton>

                        </div>
                        <EmailTemplateMobileAndDesktopPreview v-if="emailTemplateJson?.draggable_editor_data"
                            :previewType="templatePreviewType" ref="previewRender"
                            :draggable_editor_data="emailTemplateJson?.draggable_editor_data"
                            :global_style="emailTemplateJson?.global_style" :link_style="emailTemplateJson?.link_style"
                            :wrapperContainerStyle="emailTemplateJson?.wrapperContainerStyle"
                            :templateData="emailTemplateJson?.templateData" />
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<addUtmParameterModal v-if="addUtmParameteModalVisibility" :utmDropdownvalues="utmDropdownvalues" 
    @addUtmHandler="addUtmHandler"  @close="handleUtmParameterModal" :isFromFlowAutomation="isFromFlowAutomation" />

<WarningModal v-if="warningModalVisibility" :modalDetails="warningModalDetails" :onCancel="onExitCancel"
    :onConfirm="onExitConfirm" />

<PublishConfirmationModal :fetchedInfo="campaignDetails" :prePayload="payloadForPublish"
    :onCancel="onCancelPublishingStrategyModal" :onConfirm="onConfirmPublishingStrategyModal"
    v-if="publishConfirmationModalVisibility" />

<SelectAnAudienceListModal :onConfirm="onSaveList" v-if="isModalVisible" />
<selectSegmentListModal :onConfirm="onSaveSegment" v-if="isSegmentModalVisible" />

<SelectCampaignTemplate :prePayload="dataPayload" :onCancel="onCancelSelecetTemplateModal"
    v-if="selectCampaaignTemplateVisibility" />

<createCampaignVerifyYourEmail :copyClipboardMessageHandler="copyClipboardMessageHandler"
    :onConfirm="onConfirmVerifyDomainModal" :onCancel="onCancelVerifyDomainModal" :senderEmail="senderEmailForEdit"
    v-if="verifyDomainModalVisibility">
</createCampaignVerifyYourEmail>

