<template src="./SelectCampaignTemplate.html" lang=""></template>
<style lang="scss">
@import "./SelectCampaignTemplate.scss";
</style>

<script>
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { RadioButton } from "@progress/kendo-vue-inputs";
import Breadcrumb from "@/components/common/AppBreadCrumbs/BreadCrumbs.vue";
import { TabStrip, TabStripTab } from "@progress/kendo-vue-layout";
import AppSnackBarVue from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal.vue";
import { getEmailCampaignTemplateList } from "@/services/clubDetails/emailTemplate.service";
import EmailTemplateMobileAndDesktopPreview from "../../emailTemplateMobileAndDesktopPreview/EmailTemplateMobileAndDesktopPreview.vue";
import deleteCampaignTemplateModal from "../deleteCampaignTemplateModal/deleteCampaignTemplateModal.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";

export default {
  name: "SelectCampaignTemplate",
  components: {
    "k-input": Input,
    kbutton: Button,
    Breadcrumb,
    radiobutton: RadioButton,
    tabstrip: TabStrip,
    tabstripTab: TabStripTab,
    AppSnackBar: AppSnackBarVue,
    radiogroup: RadioGroup,
    modal,
    EmailTemplateMobileAndDesktopPreview,
    AppLoader,
    deleteCampaignTemplateModal,
  },
  props: {
    prePayload: {
      type: Object,
      required: true,
    },
    onCancel: { type: Function },
    onConfirm: { type: Function },
  },
  data() {
    return {
      isRemoveModalOpen: false,
      removeModalTemplateId: "",
      notifyMessage: "",
      notifyType: "",
      isNotify: false,

      isFromFlowAutomation: false,
      selectedTemplateType: {
        id: 1,
        name: "Layout",
        isSelected: true,
        value: 0,
      },

      templateTypeArray: [
        {
          id: 1,
          name: "Layout",
          isSelected: true,
          value: 0,
        },
        // {
        //   id: 2,
        //   name: "Template",
        //   isSelected: false,
        //   value: 1,
        // },
        {
          id: 3,
          name: "My Template",
          isSelected: false,
          value: 2,
        },
      ],
    };
  },

  mounted() {
    // ;
    if (
      this.$route?.path.includes("flow-create-campaign") ||
      this.$route?.path.includes("flow-edit-campaign")
    ) {
      this.isFromFlowAutomation = true;
    } else {
      this.isFromFlowAutomation = false;
    }
  },

  computed: {
    templates() {
      return this.$store.state.commonEmailCampaign?.emailCampaignList;
    },
    isLoading() {
      return this.$store.state.commonEmailCampaign
        .emailCampaignTemplateListLoading;
    },
  },

  methods: {
    onTemplateTypeHandler(type) {
      this.templateTypeArray.forEach((item) => {
        if (item.id === type.id) {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
      });
      this.selectedTemplateType = type;
      this.changeTemplateType(type.value);
    },
    changeTemplateType(type) {
      this.$store.dispatch("getEmailCampaignTemplates", {
        clubId: this.$route.params.id,
        limit: -1,
        campaignGroup: this.$route.query.flow_name ? 2 : 1,
        templateType: type,
      });
    },
    selectedTemplateNavigation(templateDetails) {
      this.$router.push({
        name: "EmailTemplateEditorUpdate",
        params: {
          templateId: templateDetails?.id,
        },
        query: {
          club_id: this.$route.params.id,
          ...(this.prePayload.campaignName && {
            campaign_name: this.prePayload.campaignName,
          }),
          ...(this.$route.params.campaignId && {
            campaignId: this.$route.params.campaignId,
          }),
          ...(this.isFromFlowAutomation && {
            campaignGroup: this.isFromFlowAutomation ? 2 : 1,
          }),

          ...(this.$route.query?.flow_name && {
            flow_name: this.$route.query?.flow_name,
          }),
          ...(this.$route.query?.flow_type && {
            flow_type: this.$route.query?.flow_type,
          }),
          ...(this.$route.query?.nodeId && {
            nodeId: this.$route.query?.nodeId,
          }),
          ...(this.$route.query?.flowId && {
            flowId: this.$route.query?.flowId,
          }),
        },
      });
    },
    generateTemplateName(name) {
      return name?.length > 25 ? name?.substring(0, 25) + "..." : name;
    },

    removeTemplate(template) {
      this.isRemoveModalOpen = true;
      this.removeModalTemplateId = template.id;
    },

    closeDeleteModal(e) {
      if (e == true) {
        this.isRemoveModalOpen = false;
        this.showNotification("Selected template has been deleted.", "success");
      } else if (e == "cancelClicked") {
        this.isRemoveModalOpen = false;
      }
      if (e == false) {
        this.showNotification("Something went wrong", "error");
        this.isRemoveModalOpen = false;
      }

      this.removeModalTemplateId = "";
    },

    showNotification(message, type, time = 4000) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(() => {
        this.notifyMessage = "";
        this.notifyType = "success";
        this.isNotify = false;
      }, time);
    },

    navigateToTemplateEditor() {
      this.$router.push({
        name: "EmailTemplateEditorCreate",
        query: {
          club_id: this.$route.params.id,
          ...(this.prePayload.campaignName && {
            campaign_name: this.prePayload.campaignName,
          }),
          ...(this.$route.params.campaignId && {
            campaignId: this.$route.params.campaignId,
          }),
          ...(this.isFromFlowAutomation && {
            campaignGroup: this.isFromFlowAutomation ? 2 : 1,
          }),

          ...(this.$route.query?.flow_name && {
            flow_name: this.$route.query?.flow_name,
          }),
          ...(this.$route.query?.flow_type && {
            flow_type: this.$route.query?.flow_type,
          }),
          ...(this.$route.query?.nodeId && {
            nodeId: this.$route.query?.nodeId,
          }),
          ...(this.$route.query?.flowId && {
            flowId: this.$route.query?.flowId,
          }),
        },
      });
    },
  },
};
</script>
<style lang=""></style>
