<template src="./CreateCampaignStepTwo.html" lang=""></template>
<style lang="scss">
@import "./CreateCampaignStepTwo.scss";
</style>

<script>
import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { RadioButton } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Popup } from "@progress/kendo-vue-popup";
import Breadcrumb from "@/components/common/AppBreadCrumbs/BreadCrumbs.vue";
import { TabStrip, TabStripTab } from "@progress/kendo-vue-layout";
import AppSnackBarVue from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import { ButtonGroup } from "@progress/kendo-vue-buttons";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import PublishConfirmationModal from "@/components/clubDetails/emailCampaign/createCampaign/publishConfirmationModal/PublishConfirmationModal.vue";
import WarningModal from "../../warningModal/WarningModal.vue";
import SelectAnAudienceListModal from "@/components/common/selectAnAudienceListModal/SelectAnAudienceListModal.vue";
import selectSegmentListModal from "@/components/common/selectSegmentList/selectSegmentListModal.vue";
import {
  createEmailCampaign,
  updateEmailCampaign,
  getEmailCampaignDetails,
  getSenderInfoService,
  checkSenderDomainService,
  verifySenderDomainService,
  getUTMFieldsService,
  getUTMFieldValuesService,
} from "@/services/clubDetails/emailCampaign.service";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import SelectCampaignTemplate from "../selectCampaignTemplate/SelectCampaignTemplate.vue";
import EmailTemplateMobileAndDesktopPreview from "../../emailTemplateMobileAndDesktopPreview/EmailTemplateMobileAndDesktopPreview.vue";
import { getEmailCampaignTemplate } from "@/services/clubDetails/emailTemplate.service";
import vClickOutside from "click-outside-vue3";
import moment from "moment";
import SubjectLineComponent from "../../subjectLineComponent/SubjectLineComponent.vue";
import createCampaignVerifyYourEmail from "../createCampaignVerifyYourEmail/createCampaignVerifyYourEmail.vue";
import { verifyCheckCampaignService } from "@/services/clubDetails/emailCampaign.service";
import BackPageIcon from "@/components/svg-icons/backPageIcon.vue";
import { Switch } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import addUtmParameterModal from "../addUtmParameterModal/addUtmParameterModal.vue";
export default {
  name: "CreateCampaignStepTwo",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    "k-input": Input,
    numericTextBox: NumericTextBox,
    dropdownlist: DropDownList,
    Popup: Popup,
    kbutton: Button,
    Breadcrumb,
    radiobutton: RadioButton,
    tabstrip: TabStrip,
    tabstripTab: TabStripTab,
    AppSnackBar: AppSnackBarVue,
    radiogroup: RadioGroup,
    buttongroup: ButtonGroup,
    dropdownbutton: DropDownButton,
    WarningModal,
    PublishConfirmationModal,
    SelectAnAudienceListModal,
    selectSegmentListModal,
    AppLoader,
    SelectCampaignTemplate,
    EmailTemplateMobileAndDesktopPreview,
    SubjectLineComponent,
    createCampaignVerifyYourEmail,
    BackPageIcon,
    "k-switch": Switch,
    checkbox: Checkbox,
    addUtmParameterModal,
  },

  data() {
    return {
      isFromFlowAutomation: false,
      isLoader: false,
      selectedReciepientType: "sendTo",

      templatePreviewType: "desktop",
      isGetSenderInfoLoading: false,

      campaignSubject: [],
      subjectLinesForEdit: [{ id: 0, campaignId: 0, subjectLine: "" }],
      isCampaignSubjectLineViewMore: false,
      isOptimizationSettingApproved: false,
      isEmailInformationApproved: false,

      campaignOptimizationType: 0,

      optimizationSettings: [
        {
          label: "Set test emails",
          value: 1,
        },
        {
          label: "Set test population",
          value: 2,
        },
      ],
      advancedOptimizationTypeForEdit: 1,
      distributionChannel: 1,
      testMetricItems: [
        { id: 2, text: "Click Rate" },
        { id: 1, text: "Open Rate" },
      ],
      testMetricValue: { id: 2, text: "Click Rate" },
      testMetricValueForEdit: { id: 2, text: "Click Rate" },
      testDurationItems: [
        { id: 5, text: "5 Minutes", value: 5 },
        { id: 2, text: "2 Hours", value: 120 },
        { id: 4, text: "4 Hours", value: 240 },
        { id: 8, text: "8 Hours", value: 480 },
        { id: 12, text: "16 Hours", value: 960 },
        { id: 24, text: "1 Day", value: 1440 },
        { id: 48, text: "2 Days", value: 2880 },
        { id: 96, text: "4 Days", value: 5760 },
        { id: 168, text: "1 Week", value: 10080 },
      ],
      testDurationValue: { id: 5, text: "5 Minutes", value: 5 },
      testDurationValueForEdit: { id: 5, text: "5 Minutes", value: 5 },
      testEmailsPerCombination: 10,
      testEmailsPerCombinationForEdit: 10,
      testPopulation: 0.1,
      testPopulationForEdit: 0.1,
      emailTemplateJson: {},
      isEmailTemplateSelected: false,
      emailTemplateId: 0,
      campaignType: this.$route.query.campaignType,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      emailInformationEditView: false,
      senderInformationEditView: false,
      recipientsInformationEditView: false,
      advancedOptimizationEditView: false,
      submitButtonStatus: [{ id: 1, text: "Save As Draft" }],
      warningModalVisibility: false,
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      warningModalDetails: {
        title: "Warning",
        message:
          "Are you sure you want to exit from setting up your campaign? Any changes made will discarded and lost. You can save the campaign as draft if you wish to edit later.",
        confirmButton: "Exit Campaign",
        cancelButton: "Cancel",
      },
      publishConfirmationModalVisibility: false,
      selectAudienceListVisibility: false,
      selectedLists: [],
      selectedListsForEdit: [],

      selectedSegments: [],
      selectedSegmentsForEdit: [],

      selectedListsForDontSend: [],
      selectedSegmentsForDontSend: [],

      selectedListsForDontSendForEdit: [],
      selectedSegmentsForDontSendForEdit: [],

      campaignName: "",
      campaignNameForEdit: "",
      storedCampaignName: "",
      distributionChannel: this.$route.query.distributionChannel,

      nameEditModalVisibility: false,

      senderName: "",
      senderNameForEdit: "",
      senderEmail: "",
      senderEmailForEdit: "",

      subjectLine: "",
      subjectLineForEdit: "",
      previewText: "",
      previewTextForEdit: "",

      payloadForPublish: {},
      isLoading: false,

      selectCampaaignTemplateVisibility: false,
      dataPayload: {},

      campaignDetails: {},
      campaignDetailsLoading: false,

      isEmailValidate: false,
      emailDomainName: "",

      isSenderDomainLoading: false,
      verifyDomainModalVisibility: false,
      isDomainVerified: false,

      isUtmLoading: false,
      isAllUtmChecked: false,
      addUtmParameteModalVisibility: false,
      utmErrors: {},
      customUtmId: null,
      utmDropdownvalues: [],
      defaultUtmValues: {
        utm_source: "Clubeez",
        utm_medium: "Campaign",
        utm_campaign: "@Model.CampaignName",
        utm_id: "@Model.CampaignId",
        utm_term: "",
      },
      linkTrackingDetails: {
        campaignUtmList: [],
        isLinkTrakingEnabled: true,
        isCustomizedLinkTrakingEnabled: false,
      },
    };
  },
  setup() {},
  computed: {
    isModalVisible() {
      return this.$store.state.common.modalVisible;
    },
    isSegmentModalVisible() {
      return this.$store.state.common.segmentModalVisibility;
    },
    clubName() {
      return this.$store.state.club.club.name;
    },
    timeZonesList() {
      return this.$store.state.commonEmailCampaign.timeZoneList;
    },
    club() {
      return this.$store.state.club.club;
    },
  },

  mounted() {
    this.checkPermission();

    if (
      this.$route?.path.includes("flow-create-campaign") ||
      this.$route?.path.includes("flow-edit-campaign")
    ) {
      this.isFromFlowAutomation = true;
      this.defaultUtmValues = {
        ...this.defaultUtmValues,
        utm_medium: "Flow",
        utm_campaign: "@Model.AutoFlowName",
        utm_id: "@Model.AutoFlowId",
      };
    } else {
      this.isFromFlowAutomation = false;
    }

    this.$store.dispatch("getTimeZonesList");
    this.$store.dispatch("getEmailCampaignTemplates", {
      clubId: this.$route.params.id,
      limit: -1,
      campaignGroup: this.$route.query.flow_name ? 2 : 1,
      templateType: 0,
    });

    if (this.$route.query.campaignName) {
      this.campaignName =
        this.$route.query.campaignName.length > 50
          ? this.$route.query.campaignName.substring(0, 50) + "..."
          : this.$route.query.campaignName;
      this.campaignNameForEdit = this.$route.query.campaignName;
      this.distributionChannel = this.$route.query.distributionChannel;
      this.$store.commit("SET_EMAIL_CAMPAIGN_NAME", this.campaignName);
    }

    if (!this.$route.query.isFromTemplate) {
      if (this.clubName) {
        this.senderName = this.clubName;
        this.senderNameForEdit = this.clubName;
      }

      if (!this.$route?.fullPath?.includes("edit")) {
        const payload = {
          clubId: this.$route.params.id,
        };
        this.getSenderInfo(payload);
      }
    }

    if (this.$route.query.isFromTemplate) {
      let localData;
      if (sessionStorage.getItem("emailCampaignData")) {
        localData = JSON.parse(sessionStorage.getItem("emailCampaignData"));
      }

      this.campaignDetails = localData;

      if (localData) this.datareloaderFunction(localData);
    }

    if (this?.$route?.params?.campaignId && !this.$route.query.isFromTemplate) {
      const payload = {
        id: this.$route.params.campaignId,
      };
      this.getCampaignDetails(payload);
    }

    if (this.$route.query.templateId) {
      this.emailTemplateId = this.$route.query.templateId;
      this.getTemplateDetails({
        id: this.$route.query.templateId,
        clubId: this.$route.params.id,
      });
    }
    this.getUTMFields();
    this.getUTMFieldValues();
    // this.doubleApiCall();
  },

  unmounted() {
    this.$store.commit("SET_EMAIL_CAMPAIGN_NAME", "");
  },
  methods: {
    deleteUTmField(index) {
      this.linkTrackingDetails.campaignUtmList.splice(index, 1);
    },
    isUtmSavable() {
      let isUtmValid = true;
      if (this.linkTrackingDetails?.isCustomizedLinkTrakingEnabled) {
        this.linkTrackingDetails.campaignUtmList.forEach((item) => {
          if (!item.utmValue && item.isActive) {
            isUtmValid = false;
          }
        });
      }
      return isUtmValid;
    },
    customUtmValidation() {
      this.utmErrors = {};

      this.linkTrackingDetails.campaignUtmList.forEach((item) => {
        if (!item.utmValue && item.isActive) {
          this.utmErrors[item.utmKey] = "Please enter UTM Value";
        }
      });
    },
    utmCheckHandler() {
      this.isAllUtmChecked = this.linkTrackingDetails.campaignUtmList.every(
        (item) => item.isActive
      );
      this.customUtmValidation()
    },
    allUtmCheckHandler() {
      this.linkTrackingDetails.campaignUtmList.forEach((item, index) => {
        if (![0, 1]?.includes(index)) {
          item.isActive = this.isAllUtmChecked;
        }
      });
      this.customUtmValidation()
    },
    addUtmHandler(data) {
      this.handleUtmParameterModal(false);
      this.linkTrackingDetails.campaignUtmList.push({
        utmFieldId: this.customUtmId,
        utmKey: data.utmKey,
        utmLabel: data.utmKey,
        utmValue: data.utmValue,
        isActive: true,
      });
    },
    handleUtmParameterModal(value) {
      this.addUtmParameteModalVisibility = value;
    },
    doubleApiCall() {
      this.isUtmLoading = true;
      Promise.all([
        getUTMFieldsService({ clubId: this.$route.params.id }),
        getUTMFieldValuesService({ clubId: this.$route.params.id }),
      ])
        .then((res) => {
          this.isUtmLoading = false;
          let fields = res[0].data.data;
          let values = res[1].data.data;
        })
        .catch((err) => {
          this.isUtmLoading = false;
        });
    },
    getUTMFields() {
      this.isUtmLoading = true;
      getUTMFieldsService({
        clubId: this.$route.params.id,
        campaignGroup: this.isFromFlowAutomation ? 2 : 1,
      })
        .then((res) => {
          this.isUtmLoading = false;
          let list = res.data.data;
          let utmList = [];
          this.customUtmId = list[list.length - 1].id;

          if (
            !this.$route.query?.isFromTemplate &&
            !this?.$route?.params?.campaignId
          ) {
            list?.forEach((item, index) => {
              if (item?.utmLabel != "Custom") {
                utmList.push({
                  utmFieldId: item.id,
                  utmKey: item.utmName,
                  utmLabel: item.utmLabel,
                  isActive: [0, 1]?.includes(index) ? true : false,
                  utmValue: this.defaultUtmValues[item.utmName],
                });
              }
            });
            this.linkTrackingDetails.campaignUtmList = utmList;
            this.utmCheckHandler();
            this.customUtmValidation();
          }
        })
        .catch((err) => {
          this.isUtmLoading = false;
        });
    },
    getUTMFieldValues() {
      this.isUtmLoading = true;
      getUTMFieldValuesService({
        clubId: this.$route.params.id,
        campaignGroup: this.isFromFlowAutomation ? 2 : 1,
      })
        .then((res) => {
          this.isUtmLoading = false;
          this.utmDropdownvalues = res.data.data;
        })
        .catch((err) => {
          this.isUtmLoading = false;
        });
    },
    checkPermission() {
      if (
        this.$route.name == "CampaignEdit" &&
        this?.$route?.params?.campaignId
      ) {
        if (!this.hasCampaignPutPermission()) {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "You do not have access to edit campaign.",
            type: "warning",
          });
          this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
        }
      }
      if (
        this.$route.name == "CreateCampaignStepTwo" &&
        !this?.$route?.params?.campaignId
      ) {
        if (!this.hasCampaignPostPermission()) {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "You do not have access to create campaign.",
            type: "warning",
          });
          this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
        }
      }

      if (
        this.$route.name == "flowCreateCampaign" &&
        !this?.$route?.params?.campaignId
      ) {
        if (!this.hasFlowCampaignPostPermission()) {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "You do not have access to create campaign.",
            type: "warning",
          });
          this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
        }
      }

      if (
        this.$route.name == "flowEditCampaign" &&
        this?.$route?.params?.campaignId
      ) {
        if (!this.hasFlowCampaignPutPermission()) {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "You do not have access to edit campaign.",
            type: "warning",
          });
          this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
        }
      }
    },
    hasCampaignPutPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: "emailCampaign",
        itemCode: "allCampaign",
        childItemCode: null,
      });
    },
    hasCampaignPostPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: "emailCampaign",
        itemCode: "allCampaign",
        childItemCode: null,
      });
    },
    hasCampaignGetPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: "emailCampaign",
        itemCode: "allCampaign",
        childItemCode: null,
      });
    },
    hasFlowCampaignPostPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    hasFlowCampaignPutPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    hasFlowCampaignGetPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },

    backNavigateToFlowEditor() {
      this.$router.push({
        name: "workflowEditor",
        query: {
          nodeId: this.$route.query.nodeId,
          flow_name: this.$route.query.flow_name,
          flow_type: this.$route.query.flow_type,
          isFromCampaign: true,
          campaign_id: this?.$route?.params?.campaignId,
          isFromBackAction: true,
          subject: this.campaignSubject[0]?.subjectLine,
          from: this.senderEmail,
          ...(this.$route.query.flowId && {
            flowId: this.$route.query.flowId,
          }),
        },
        params: {
          id: this.$route.params.id,
          node_id: this.$route.query.nodeId,
          ...(this.$route.query.flowId && {
            flowId: this.$route.query.flowId,
          }),
        },
      });
    },
    doNavigateToFlowEditor(campaignInfo) {
      this.$router.push({
        name: "workflowEditor",
        query: {
          nodeId: this.$route.query.nodeId,
          flow_name: this.$route.query.flow_name,
          flow_type: this.$route.query.flow_type,
          isFromCampaign: true,
          campaign_id: campaignInfo.id,
          subject: campaignInfo.subjectLine,
          from: campaignInfo.senderEmail,
          ...(this.$route.query.flowId && {
            flowId: this.$route.query.flowId,
          }),
        },
        params: {
          id: this.$route.params.id,
          node_id: this.$route.query.nodeId,
          ...(this.$route.query.flowId && {
            flowId: this.$route.query.flowId,
          }),
        },
      });
    },
    toggleDomainNameVerifyModal() {
      this.verifyDomainModalVisibility = true;
    },
    onCancelVerifyDomainModal() {
      this.verifyDomainModalVisibility = false;
    },
    onConfirmVerifyDomainModal(payload) {
      this.isSenderDomainLoading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };
      verifySenderDomainService(payload)
        .then((res) => {
          this.isSenderDomainLoading = false;
          // ;
          if (res?.data?.data) {
            this.verifyDomainModalVisibility = false;
            this.senderEmail = this.senderEmailForEdit;
            this.isDomainVerified = false;
            this.showNotification(
              "Your domain has been verified for sending emails.",
              "success"
            );
          } else {
            this.showNotification(
              "We couldn't find your verification records in your domain's records. Sometimes DNS changes can take a while to appear. Please wait a few hours and try again.",
              "error",
              10000
            );
          }
        })
        .catch((err) => {
          this.isSenderDomainLoading = false;
        });
    },
    copyClipboardMessageHandler() {
      this.showNotification("Copied to clipboard", "success");
    },
    checkSenderDomainName(payload) {
      this.isSenderDomainLoading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };
      checkSenderDomainService(payload)
        .then((res) => {
          this.isSenderDomainLoading = false;
          this.isDomainVerified = !res?.data?.data;
          if (res?.data?.data) {
            this.senderEmail = this.senderEmailForEdit;
          }
          if (this.isDomainVerified) {
            this.senderInformationEditView = true;
          }
        })
        .catch((err) => {
          this.isSenderDomainLoading = false;
        });
    },
    editTemplateHandler() {
      this.localDataSaveHandler();
      this.$router.push({
        name: "EmailTemplateEditorUpdate",
        params: {
          templateId: this.emailTemplateId,
        },
        query: {
          club_id: this.$route.params.id,
          campaign_name: this.$route.query.campaignName,
          isForEdit: true,
          campaignId: this.$route.params.campaignId,
          campaignGroup: this.isFromFlowAutomation ? 2 : 1,
          flow_name: this.$route.query.flow_name,
          flow_type: this.$route.query.flow_type,
          nodeId: this.$route.query.nodeId,
          campaignGroup: this.$route.query.nodeId ? 2 : 1,
          ...(this.$route.query.flowId && { flowId: this.$route.query.flowId }),
        },
      });
    },
    templateViewToggle(type) {
      this.templatePreviewType = type;
    },
    toggleSubjectLineViewMore() {
      this.isCampaignSubjectLineViewMore = !this.isCampaignSubjectLineViewMore;
    },
    onSaveAdvancedOptimization() {
      this.advancedOptimizationEditView = false;
      this.campaignOptimizationType = this.advancedOptimizationTypeForEdit;
      this.testMetricValue = this.testMetricValueForEdit;
      this.testDurationValue = this.testDurationValueForEdit;
      this.testEmailsPerCombination = this.testEmailsPerCombinationForEdit;
      this.testPopulation = this.testPopulationForEdit;
      this.isOptimizationSettingApproved = true;
    },
    advanceOptimizationSavebuttonVisibility() {
      //
      //   this.advancedOptimizationTypeForEdit,
      //   this.testEmailsPerCombinationForEdit,
      //   this.testPopulationForEdit,
      //   "c98"
      // );

      if (
        this.advancedOptimizationTypeForEdit == 1 &&
        this.testEmailsPerCombinationForEdit > 0
      ) {
        return false;
      }

      if (
        this.advancedOptimizationTypeForEdit == 2 &&
        this.testPopulationForEdit * 100 > 0
      ) {
        return false;
      }

      return true;
    },
    toggleAdvancedOptimizationEditView(value) {
      this.advancedOptimizationEditView = value;
      if (!value) {
        this.advancedOptimizationTypeForEdit =
          this.campaignOptimizationType == 0
            ? 1
            : this.campaignOptimizationType;
        this.testMetricValueForEdit = this.testMetricValue;
        this.testDurationValueForEdit = this.testDurationValue;
        this.testEmailsPerCombinationForEdit = this.testEmailsPerCombination;
        this.testPopulationForEdit = this.testPopulation;
      }
    },
    handleAdvancedOptimizationType(e) {
      this.advancedOptimizationTypeForEdit = e.value;
    },
    subjectLineCloseHandler(index) {
      if (this.subjectLinesForEdit.length > 1) {
        this.subjectLinesForEdit.splice(index, 1);
      } else {
        this.showNotification("You can't delete all subject lines", "warning");
      }
    },
    handleTestMetricValue(e) {
      this.testMetricValueForEdit = e.value;
    },
    handleTestDurationValue(e) {
      this.testDurationValueForEdit = e.value;
    },
    addSubjectLineFieldHanlder() {
      if (this.subjectLinesForEdit.length < 5) {
        this.subjectLinesForEdit.push({
          id: 0,
          campaignId: 0,
          subjectLine: "",
        });
      } else {
        this.showNotification(
          "You can't add more than 5 subject lines",
          "warning"
        );
      }
    },
    onSyncSubjectLine(data) {
      this.subjectLineForEdit = data;
    },
    onSyncSubjectLines(data, index) {
      this.subjectLinesForEdit[index].subjectLine = data;
    },
    onSyncPreviewText(data) {
      this.previewTextForEdit = data;
    },
    onClickOutside(event) {
      this.emojiPickerVisibilityForPreviewText = false;
      this.emojiPickerVisibilityForSubjectLine = false;
    },
    getTemplateDetails(payload) {
      this.campaignDetailsLoading = true;
      getEmailCampaignTemplate(payload)
        .then((res) => {
          this.campaignDetailsLoading = false;
          // , "x658");
          this.emailTemplateJson = JSON.parse(
            res?.data?.data?.templateJsonObject
          );
          this.isEmailTemplateSelected = true;
        })
        .catch((err) => {
          this.campaignDetailsLoading = false;
        });
    },
    getSenderInfo(payload) {
      this.isGetSenderInfoLoading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };
      getSenderInfoService(payload)
        .then((res) => {
          // ;
          this.isGetSenderInfoLoading = false;
          this.senderEmailForEdit = res?.data?.data?.senderEmail;
          this.senderName = res?.data?.data?.senderName;
          this.senderNameForEdit = res?.data?.data?.senderName;
          if (this.senderEmailForEdit) {
            let domainName = this.senderEmailForEdit.split("@")[1];
            if (domainName) {
              const payload = {
                domainName: domainName,
              };
              this.checkSenderDomainName(payload);
            }
          }
        })
        .catch((err) => {
          this.isGetSenderInfoLoading = false;
        });
    },
    getCampaignDetails(payload) {
      this.campaignDetailsLoading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };
      getEmailCampaignDetails(payload)
        .then((res) => {
          this.campaignDetails = res.data.data;
          // sessionStorage.setItem(
          //   "emailCampaignData",
          //   JSON.stringify(this.campaignDetails)
          // );
          if (this.campaignDetails?.emailCampaignTemplateId) {
            this.emailTemplateId =
              this.campaignDetails?.emailCampaignTemplateId;
            this.isEmailTemplateSelected = true;
            this.getTemplateDetails({
              id: this.campaignDetails?.emailCampaignTemplateId,
              clubId: this.$route.params.id,
            });
          } else {
            this.campaignDetailsLoading = false;
          }
          this.$store.commit(
            "SET_EMAIL_CAMPAIGN_NAME",
            this.campaignDetails?.campaignName
          );

          // ;
          this.datareloaderFunction(this.campaignDetails);
        })
        .catch((err) => {
          this.campaignDetailsLoading = false;
        });
    },

    datareloaderFunction(localData) {
      let SubjectValueDontExist = false;

      if (localData?.campaignSubject?.length == 0) {
        SubjectValueDontExist = true;
      } else {
        for (let item of localData?.campaignSubject) {
          if (!item?.subjectLine) {
            SubjectValueDontExist = true;
            break;
          }
        }
      }

      this.linkTrackingDetails = {
        campaignUtmList: localData?.campaignUtmList || [],
        isLinkTrakingEnabled: localData?.isLinkTrakingEnabled || false,
        isCustomizedLinkTrakingEnabled:
          localData?.isCustomizedLinkTrakingEnabled || false,
      };
      this.utmCheckHandler();
      this.customUtmValidation();

      this.isEmailInformationApproved = !SubjectValueDontExist;

      this.campaignSubject =
        localData?.campaignSubject?.length == 0
          ? [{ id: 0, campaignId: 0, subjectLine: "" }]
          : JSON.parse(JSON.stringify(localData?.campaignSubject));
      this.subjectLinesForEdit =
        localData?.campaignSubject?.length == 0
          ? [{ id: 0, campaignId: 0, subjectLine: "" }]
          : JSON.parse(JSON.stringify(localData?.campaignSubject));

      this.previewText = localData?.previewText;
      this.previewTextForEdit = localData?.previewText;

      this.selectedLists = localData?.campaignAudienceList;
      this.selectedListsForEdit = localData?.campaignAudienceList;

      this.selectedSegments = localData?.campaignSegment;
      this.selectedSegmentsForEdit = localData?.campaignSegment;

      this.selectedListsForDontSend =
        localData?.campaignAudienceListExcept || [];
      this.selectedListsForDontSendForEdit =
        localData?.campaignAudienceListExcept || [];

      this.selectedSegmentsForDontSend = localData?.campaignSegmentExcept || [];
      this.selectedSegmentsForDontSendForEdit =
        localData?.campaignSegmentExcept || [];

      this.senderName = localData?.senderName;
      this.senderNameForEdit = localData?.senderName;

      if (localData?.senderEmail) {
        this.senderEmail = localData?.senderEmail;
        this.senderEmailForEdit = localData?.senderEmail;
      } else {
        this.senderEmailForEdit = localData?.senderEmailForEdit;
        this.senderInformationEditView = true;
        if (localData?.senderEmailForEdit) {
          let domainName = localData?.senderEmailForEdit.split("@")[1];
          if (domainName) {
            const payload = {
              domainName: domainName,
            };
            this.checkSenderDomainName(payload);
          }
        }
      }

      this.campaignName =
        localData?.campaignName.length > 50
          ? localData?.campaignName.substring(0, 50) + "..."
          : localData?.campaignName;
      this.campaignNameForEdit = localData?.campaignName;

      this.$store.commit("SET_EMAIL_CAMPAIGN_NAME", this.campaignName);

      this.distributionChannel = localData?.distributionChannel;
      this.campaignType = localData?.campaignType;

      if (localData?.campaignOptimization?.campaignOptimizationType) {
        //
        //   localData?.campaignOptimization?.campaignOptimizationType,
        //   "s37"
        // );

        this.campaignOptimizationType =
          localData?.campaignOptimization?.campaignOptimizationType;
        this.advancedOptimizationTypeForEdit =
          localData?.campaignOptimization?.campaignOptimizationType;

        this.testMetricValue = this.testMetricItems.filter(
          (item) =>
            item.id === localData?.campaignOptimization?.campaignTestMetric
        )[0];

        this.testMetricValueForEdit = this.testMetricValue;

        this.testDurationValue = this.testDurationItems.filter(
          (item) => item.value === localData?.campaignOptimization?.testDuration
        )[0];
        this.testDurationValueForEdit = this.testDurationValue;

        if (localData?.campaignOptimization?.campaignOptimizationType == 1) {
          this.testEmailsPerCombination =
            localData?.campaignOptimization?.ratioCount;
          this.testEmailsPerCombinationForEdit =
            localData?.campaignOptimization?.ratioCount;
        }

        if (localData?.campaignOptimization?.campaignOptimizationType == 2) {
          this.testPopulation =
            localData?.campaignOptimization?.ratioCount / 100;
          this.testPopulationForEdit =
            localData?.campaignOptimization?.ratioCount / 100;
        }

        this.isOptimizationSettingApproved = true;
      }
    },

    publishButtonApperance() {
      if (this.campaignType == 1) {
        if (
          this.campaignName &&
          this.distributionChannel &&
          this.senderName &&
          this.senderEmail &&
          this.campaignSubject[0]?.subjectLine &&
          (this.selectedLists.length > 0 || this.selectedSegments.length > 0)
          //  && (this.hasCampaignPostPermission() == true || this.hasCampaignPutPermission() == true)
        ) {
          return false;
        } else {
          return true;
        }
      }

      if (this.campaignType == 2) {
        let isSubjectLinePresent = false;
        for (let item of this.campaignSubject) {
          if (item.subjectLine) {
            isSubjectLinePresent = true;
          } else {
            isSubjectLinePresent = false;
            break;
          }
        }

        if (
          this.campaignName &&
          this.distributionChannel &&
          this.senderName &&
          this.senderEmail &&
          isSubjectLinePresent &&
          (this.selectedLists.length > 0 || this.selectedSegments.length > 0) &&
          this.isOptimizationSettingApproved
        ) {
          return false;
        } else {
          return true;
        }
      }
    },

    doneButtonApperance() {
      if (
        this.senderName &&
        this.senderEmail &&
        this.campaignSubject[0]?.subjectLine
        // && (this.hasFlowCampaignPostPermission() == true || this.hasFlowCampaignPutPermission() == true)
      ) {
        return false;
      } else {
        return true;
      }
    },

    localDataSaveHandler() {
      this.dataPayload = {
        ...this.linkTrackingDetails,
        clubId: this.$route.params.id,
        campaignName: this.campaignNameForEdit,
        distributionChannel: this.distributionChannel,
        campaignType: this.campaignType,
        previewText: this.previewText,
        senderName: this.senderName,
        senderEmail: this.senderEmail,
        senderEmailForEdit: this.senderEmailForEdit,
        campaignAudienceList: this.selectedLists,
        campaignSegment: this.selectedSegments,
        campaignAudienceListExcept: this.selectedListsForDontSend,
        campaignSegmentExcept: this.selectedSegmentsForDontSend,
        campaignSubject: this.campaignSubject,
        ...(this.campaignType == 2 && {
          campaignOptimization: {
            id: 0,
            campaignId: 0,
            campaignOptimizationType: this.campaignOptimizationType,
            campaignTestMetric: this?.testMetricValue?.id,
            testDuration: this?.testDurationValue?.value,
            ratioCount:
              this.campaignOptimizationType == 1
                ? this.testEmailsPerCombination
                : (this.testPopulation * 100).toFixed(),
          },
        }),
      };

      // ;

      sessionStorage.setItem(
        "emailCampaignData",
        JSON.stringify({
          ...this.campaignDetails,
          ...this.dataPayload,
        })
      );

      // if (this.campaignDetails?.id) {
      //   sessionStorage.setItem(
      //     "emailCampaignData",
      //     JSON.stringify(this.campaignDetails)
      //   );
      // } else {
      //   sessionStorage.setItem(
      //     "emailCampaignData",
      //     JSON.stringify(this.dataPayload)
      //
      // }
    },
    openSelectTemplateModal() {
      this.localDataSaveHandler();
      setTimeout(() => {
        this.selectCampaaignTemplateVisibility = true;
      }, 50);
    },

    onCancelSelecetTemplateModal() {
      this.selectCampaaignTemplateVisibility = false;
      sessionStorage.removeItem("emailCampaignData");
    },

    onSaveEmailInformation() {
      if (this.emailInformationValidation()) {
        this.emailInformationEditView = false;
        this.isEmailInformationApproved = true;
        // this.subjectLine = this.subjectLineForEdit;
        this.campaignSubject = JSON.parse(
          JSON.stringify(this.subjectLinesForEdit)
        );
        this.previewText = this.previewTextForEdit;
      }
    },

    onRemoveList(listId, type) {
      this[type] = this?.[type]?.filter(
        (item) => item?.audienceListId !== listId
      );
    },

    onRemoveSegment(segmentId, type) {
      this[type] = this?.[type]?.filter(
        (item) => item?.segmentId !== segmentId
      );
    },

    emailInformationValidation() {
      if (this.subjectLinesForEdit.length > 1) {
        for (const [index, value] of this.subjectLinesForEdit.entries()) {
          if (value?.subjectLine.trim().length === 0) {
            this.showNotification(
              `Please enter subject line ${index + 1}.`,
              "warning"
            );
            return false;
          }
          if (value?.subjectLine.length > 150) {
            this.showNotification(
              `Subject line ${index + 1} must not exceed 150 characters.`,
              "warning"
            );
            return false;
          }
        }
      } else {
        for (const [index, value] of this.subjectLinesForEdit.entries()) {
          if (value?.subjectLine.trim().length === 0) {
            this.showNotification(`Please enter subject line.`, "warning");
            return false;
          }
          if (value?.subjectLine.length > 150) {
            this.showNotification(
              `Subject line must not exceed 150 characters.`,
              "warning"
            );
            return false;
          }
        }
      }

      if (this.previewTextForEdit && this.previewTextForEdit.length > 150) {
        this.showNotification(
          "Preview text should not be more than 150 characters.",
          "warning"
        );
        return false;
      }

      return true;
    },

    senderInformationValidation() {
      const isEmailValid = () => {
        const email = this.senderEmailForEdit;
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      };

      if (this.senderNameForEdit == "") {
        this.showNotification("Please enter sender name.", "warning");
        return false;
      }
      if (this.senderEmailForEdit == "") {
        this.showNotification("Please enter sender email.", "warning");
        return false;
      }

      if (this.senderNameForEdit.trim().length === 0) {
        this.showNotification("Please enter sender name.", "warning");
        return false;
      }
      if (this.senderNameForEdit.length > 100) {
        this.showNotification(
          "Sender name should not be more than 100 characters.",
          "warning"
        );
        return false;
      }
      if (!isEmailValid()) {
        this.showNotification("Please enter valid email address.", "warning");
        return false;
      }

      return true;
    },

    onSaveSenderInformation() {
      if (this.senderInformationValidation()) {
        let domainName = this.senderEmailForEdit.split("@")[1];
        if (domainName) {
          const payload = {
            domainName: domainName,
            clubId: this.$route.params.id,
          };

          this.isSenderDomainLoading = true;
          checkSenderDomainService(payload)
            .then((res) => {
              this.isSenderDomainLoading = false;
              this.isDomainVerified = !res?.data?.data;
              if (res?.data?.data) {
                this.senderEmail = this.senderEmailForEdit;
                this.senderName = this.senderNameForEdit;
                this.senderInformationEditView = false;
              } else {
                this.showNotification(
                  "Please enter verified email address.",
                  "warning"
                );
              }
            })
            .catch((err) => {
              this.isSenderDomainLoading = false;
            });
        }
      }
    },

    dateValidation() {
      if (!this.campaignName) {
        this.showNotification("Please enter campaign name.", "warning");
        return false;
      }

      if (!this.senderName) {
        this.showNotification("Please enter sender name.", "warning");
        return false;
      }

      if (!this.senderEmail) {
        this.showNotification("Please enter sender email.", "warning");
        return false;
      }

      if (
        this.selectedLists.length === 0 &&
        this.selectedSegments.length === 0
      ) {
        this.showNotification("Please add recipients.", "warning");
        return false;
      }

      if (!this.isEmailTemplateSelected) {
        this.showNotification("Please select email template.", "warning");
        return false;
      }
      return true;
    },

    flowDoneValidation() {
      if (!this.senderName) {
        this.showNotification("Please enter sender name.", "warning");
        return false;
      }

      if (!this.senderEmail) {
        this.showNotification("Please enter sender email.", "warning");
        return false;
      }

      if (!this.isEmailTemplateSelected) {
        this.showNotification("Please select email template.", "warning");
        return false;
      }
      return true;
    },

    nameDataValidation() {
      if (!this.campaignNameForEdit) {
        this.showNotification("Cmpaign name is required.", "warning");
        return false;
      }
      if (this.campaignNameForEdit.length > 100) {
        this.showNotification(
          "Campaign name should not exceed 100 characters.",
          "warning"
        );
        return false;
      }
      if (this.campaignNameForEdit.trim().length === 0) {
        this.showNotification("Campaign name should not be empty.", "warning");
        return false;
      }
      return true;
    },

    nameEditModalHandler() {
      this.nameEditModalVisibility = !this.nameEditModalVisibility;
      if (!this.nameEditModalVisibility) {
          this.campaignNameForEdit = this.campaignName;
      }
    },

    onSaveCampaignName() {
      if (this.nameDataValidation()) {
        this.nameVerifyCheckCampaignService();
      }
    },

    onCancelNameEditModal() {
      this.campaignNameForEdit = this.campaignName;
      this.nameEditModalVisibility = !this.nameEditModalVisibility;
    },

    nameVerifyCheckCampaignService() {
      this.isLoader = true;
      var payload = {
        clubId: this.$route?.params?.id,
        campaignName: this.campaignNameForEdit,
        campaignGroup: 1,
      };
      verifyCheckCampaignService(payload)
        .then((response) => {
          this.isLoader = false;
          if (!response.data.data) {
            this.campaignName =
              this.campaignNameForEdit.length > 50
                ? this.campaignNameForEdit.substring(0, 50) + "..."
                : this.campaignNameForEdit;
            this.nameEditModalVisibility = !this.nameEditModalVisibility;
            this.$store.commit("SET_EMAIL_CAMPAIGN_NAME", this.campaignName);
          } else {
            this.showNotification("Campaign name already exists.", "warning");
            return;
          }
        })
        .catch((err) => {
          this.isLoader = false;
        });
      return this.isDuplicateName;
    },

    onSaveList(lists) {
      let selectedListsIds = [];
      selectedListsIds = [
        ...this.selectedListsForEdit.map((item) => item.audienceListId),
        ...this.selectedListsForDontSendForEdit.map(
          (item) => item.audienceListId
        ),
      ];

      const newList = lists
        .map((item) => {
          return {
            id: 0,
            campaignId: 0,
            audienceListId: item.id,
            audienceListName:
              item.name.length > 25
                ? item.name.substring(0, 25) + "..."
                : item.name,
          };
        })
        .filter((item) => !selectedListsIds?.includes(item.audienceListId));

      if (this.selectedReciepientType == "sendTo") {
        this.selectedListsForEdit = [...this?.selectedListsForEdit, ...newList];
      } else {
        this.selectedListsForDontSendForEdit = [
          ...this?.selectedListsForDontSendForEdit,
          ...newList,
        ];
      }
    },

    onSaveSegment(segments) {
      const selectedSegmentsIds = [
        ...this.selectedSegmentsForEdit.map((item) => item.segmentId),
        ...this.selectedSegmentsForDontSendForEdit.map(
          (item) => item.segmentId
        ),
      ];

      const newSegment = segments
        .map((item) => {
          return {
            id: 0,
            campaignId: 0,
            segmentId: item.id,
            segmentName:
              item.segmentName.length > 25
                ? item.segmentName.substring(0, 25) + "..."
                : item.segmentName,
          };
        })
        .filter((item) => !selectedSegmentsIds?.includes(item.segmentId));

      if (this.selectedReciepientType == "sendTo") {
        this.selectedSegmentsForEdit = [
          ...this.selectedSegmentsForEdit,
          ...newSegment,
        ];
      } else {
        this.selectedSegmentsForDontSendForEdit = [
          ...this.selectedSegmentsForDontSendForEdit,
          ...newSegment,
        ];
      }
    },

    onSaveRecipients() {
      this.selectedLists = this.selectedListsForEdit;
      this.selectedSegments = this.selectedSegmentsForEdit;
      this.selectedListsForDontSend = this.selectedListsForDontSendForEdit;
      this.selectedSegmentsForDontSend =
        this.selectedSegmentsForDontSendForEdit;
      this.recipientsInformationEditView = false;
    },

    togleAudienceListModal(type) {
      this.selectedReciepientType = type;
      this.$store.commit("MODAL_VISIBLE_COMMON", true);
    },
    toggleSegmentListModal(type) {
      this.selectedReciepientType = type;
      this.$store.commit("SET_SEGMENT_MODAL_VISIBILITY", true);
    },

    onCancelPublishingStrategyModal() {
      this.publishConfirmationModalVisibility = false;
    },
    onConfirmPublishingStrategyModal(payload) {
      this.publishConfirmationModalVisibility = false;
      this.createEmailCampaignHandler(payload);
    },

    getDate(date) {
      if (date != null) {
        return moment(date).format("MMM Do YYYY");
      }
    },
    timeFormatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes.toString().padStart(2, "0");
      let strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },

    createEmailCampaignHandler(payload) {
      sessionStorage.removeItem("emailCampaignData");
      // ;
      if (this?.$route?.params?.campaignId) {
        // payload.id = this.$route.params.campaignId;
        // ;
        let updatePayload = {
          ...this.campaignDetails,
          ...payload,
          campaignGroup: 1,
        };
        this.isLoading = true;
        updateEmailCampaign(updatePayload)
          .then((res) => {
            this.isLoading = false;

            if (res?.data?.status?.message === "success") {
              if (payload?.campaignStatus == 1) {
                this.showNotification("Campaign updated as draft.", "success");
              } else {
                if (payload?.publishOption == 1) {
                  this.showNotification(
                    "Your campaign is now live and emails are on the way.",
                    "success"
                  );
                }
                if (payload?.publishOption == 2) {
                  this.showNotification(
                    `Your campaign is scheduled for sending on ${this.getDate(
                      new Date(payload?.dateOfSchedule)
                    )} at ${this.timeFormatAMPM(
                      new Date(payload?.dateOfSchedule)
                    )}`,
                    "success"
                  );
                }
              }
              setTimeout(() => {
                this.doNavigateToCampaignHomepage();
              }, 1500);
            }

            if (res?.data?.status?.message === "error") {
              this.showNotification(res?.data?.data?.message, "error");
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.showNotification("Something went wrong", "error");
            this.showNotification(
              err?.response?.data?.Status?.Message || "Something went wrong",
              "error"
            );
          });
      } else {
        this.isLoading = true;

        createEmailCampaign({
          ...payload,
          campaignGroup: 1,
        })
          .then((res) => {
            this.isLoading = false;

            if (res?.data?.status?.message === "success") {
              if (payload?.campaignStatus == 1) {
                this.showNotification("Campaign saved as draft.", "success");
              } else {
                if (payload?.publishOption == 1) {
                  this.showNotification(
                    "Your campaign is now live and emails are on the way.",
                    "success"
                  );
                }
                if (payload?.publishOption == 2) {
                  this.showNotification(
                    `Your campaign is scheduled for sending on ${this.getDate(
                      new Date(payload?.dateOfSchedule)
                    )} at ${this.timeFormatAMPM(
                      new Date(payload?.dateOfSchedule)
                    )}`,
                    "success"
                  );
                }
              }
              setTimeout(() => {
                this.doNavigateToCampaignHomepage();
              }, 1500);
            }
            if (res?.data?.status?.message === "error") {
              this.showNotification(res?.data?.data?.message, "error");
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.showNotification(
              err?.response?.data?.Status?.Message || "Something went wrong",
              "error"
            );
          });
      }
    },

    createEmailCampaignHandlerForFlowAutomation(payload) {
      sessionStorage.removeItem("emailCampaignData");
      // ;
      if (this?.$route?.params?.campaignId) {
        let updatePayload = {
          ...this.campaignDetails,
          ...payload,
        };
        // ;
        // ;
        // return;
        this.isLoading = true;
        updateEmailCampaign(updatePayload)
          .then((res) => {
            this.isLoading = false;
            if (res?.data?.status?.message === "success") {
              setTimeout(() => {
                this.doNavigateToFlowEditor(res?.data?.data);
              }, 100);
            }
            if (res?.data?.status?.message === "error") {
              this.showNotification(res?.data?.data?.message, "error");
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.showNotification("Something went wrong", "error");
          });
      } else {
        this.isLoading = true;
        createEmailCampaign(payload)
          .then((res) => {
            this.isLoading = false;
            // ;

            if (res?.data?.status?.message === "success") {
              setTimeout(() => {
                this.doNavigateToFlowEditor(res?.data?.data);
              }, 100);
            }
            if (res?.data?.status?.message === "error") {
              this.showNotification(res?.data?.data?.message, "error");
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.showNotification("Something went wrong", "error");
          });
      }
    },

    onPublishButtonHandler() {
      if (this.dateValidation()) {
        this.payloadForPublish = {
          clubId: this.$route.params.id,
          campaignName: this.campaignNameForEdit,
          distributionChannel: this.distributionChannel,
          campaignType: this.campaignType,
          previewText: this.previewText,
          senderName: this.senderName,
          senderEmail: this.senderEmail,
          campaignAudienceList: this.selectedLists,
          campaignSegment: this.selectedSegments,
          campaignAudienceListExcept: this.selectedListsForDontSend,
          campaignSegmentExcept: this.selectedSegmentsForDontSend,
          campaignSubject: this.campaignSubject,
          ...this.linkTrackingDetails,
          ...(this.campaignType == 2 && {
            campaignOptimization: {
              id: 0,
              campaignId: 0,
              campaignOptimizationType: this.campaignOptimizationType,
              campaignTestMetric: this?.testMetricValue?.id,
              testDuration: this?.testDurationValue?.value,
              ratioCount:
                this.campaignOptimizationType == 1
                  ? this.testEmailsPerCombination
                  : this.testPopulation * 100,
            },
          }),
        };
        setTimeout(() => {
          this.publishConfirmationModalVisibility = true;
        }, 200);
      }
    },
    onDoneButtonHandler() {
      let timeZone = this.club.timeZoneId;
      let timeZoneValue = this.timeZonesList.find(
        (item) => item.id === timeZone
      );
      if (this.flowDoneValidation()) {
        this.payloadForFlowDone = {
          clubId: this.$route.params.id,
          campaignName: `${this.$route.query.flow_name}-${this.$route.query.nodeId}`,
          campaignGroup: this.isFromFlowAutomation ? 2 : 1,
          previewText: this.previewText,
          senderName: this.senderName,
          senderEmail: this.senderEmail,
          campaignSubject: this.campaignSubject,
          publishOption: 1,
          scheduleType: 1,
          distributionChannel: 1,
          timeZoneId: timeZoneValue?.id,
          campaignStatus: 2,
          utcTimeOfSend: new Date().toUTCString(),
          ...(this.$route.query.templateId && {
            emailCampaignTemplateId: this.$route.query.templateId,
          }),
          ...this.linkTrackingDetails,
        };
      }
      this.createEmailCampaignHandlerForFlowAutomation(this.payloadForFlowDone);
    },
    onExitConfirm() {
      this.warningModalVisibility = false;
      this.doNavigateToCampaignHomepage();
    },
    onExitCancel() {
      this.warningModalVisibility = false;
    },
    onCancelButtonHandler() {
      this.warningModalVisibility = true;
    },
    saveAsDraftHandler(e, id) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const timeZoneValue = this.timeZonesList.find((item) => {
        return item.name === timeZone;
      });
      const payload = {
        clubId: this.$route.params.id,
        campaignName: this.campaignNameForEdit,
        distributionChannel: this.distributionChannel,
        campaignType: this.campaignType,
        previewText: this.previewText,
        senderName: this.senderName,
        senderEmail: this.senderEmail,
        campaignAudienceList: this.selectedLists,
        campaignSegment: this.selectedSegments,
        campaignAudienceListExcept: this.selectedListsForDontSend,
        campaignSegmentExcept: this.selectedSegmentsForDontSend,
        timeZoneId: timeZoneValue?.id,
        campaignStatus: 1,
        emailCampaignTemplateId:
          +this.$route.query.templateId ||
          this.campaignDetails?.emailCampaignTemplateId ||
          null,
        campaignSubject: this.campaignSubject,
        ...this.linkTrackingDetails,
        ...(this.campaignType == 2 && {
          campaignOptimization: {
            id: 0,
            campaignId: 0,
            campaignOptimizationType: this.campaignOptimizationType,
            campaignTestMetric: this?.testMetricValue?.id,
            testDuration: this?.testDurationValue?.value,
            ratioCount:
              this.campaignOptimizationType == 1
                ? this.testEmailsPerCombination
                : (this.testPopulation * 100).toFixed(),
          },
        }),
      };

      this.createEmailCampaignHandler(payload);
    },
    toggleEmailInformationEditView(value) {
      this.emailInformationEditView = value;

      if (!value) {
        if (this.campaignSubject.length == 0) {
          this.subjectLinesForEdit = [
            {
              id: 0,
              campaignId: 0,
              subjectLine: "",
            },
          ];
        } else {
          this.subjectLinesForEdit = JSON.parse(
            JSON.stringify(this.campaignSubject)
          );
        }
        this.previewTextForEdit = this.previewText;
      }
    },

    toggleSenderInformationEditView(value) {
      this.senderInformationEditView = value;

      if (!value) {
        this.senderEmailForEdit = this.senderEmail;
        this.senderNameForEdit = this.senderName;
      }
    },

    toggleRecipientsInformationEditView(value) {
      this.recipientsInformationEditView = value;

      if (!value) {
        this.selectedListsForEdit = this.selectedLists;
        this.selectedSegmentsForEdit = this.selectedSegments;
        this.selectedListsForDontSendForEdit = this.selectedListsForDontSend;
        this.selectedSegmentsForDontSendForEdit =
          this.selectedSegmentsForDontSend;
      }
    },

    showNotification(message, type, time = 4000) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(() => {
        this.notifyMessage = "";
        this.notifyType = "success";
        this.isNotify = false;
      }, time);
    },

    doNavigateToCampaignHomepage() {
      this.$router.push({
        name: "AllCampaign",
      });
    },
  },
};
</script>
<style lang=""></style>
