<div class="campaign-create-input-box d-flex w-100">
    <k-input ref="subjectLineInput" :placeholder=" placeHolder ? placeHolder :  'Enter Subject Line'" type="text"
        :value="subjectLine" @input="inputHandler" aria-required="true">
    </k-input>
    <div class="emoji-wrap">
        <span @click="emojiToggleHandlerForSubjectLine" class="emoji">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                    <g clip-path="url(#clip0_5853_36336)">
                        <path
                            d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM5.15467 10.4273C6.66416 11.9463 9.08775 12.0045 10.6671 10.5961L10.8453 10.4273C11.04 10.2314 11.3566 10.2304 11.5524 10.4251C11.7265 10.5981 11.7467 10.8674 11.6123 11.0627L11.5547 11.1322L11.5323 11.1545C9.5691 13.1054 6.39616 13.0953 4.44533 11.1322C4.25069 10.9363 4.25169 10.6197 4.44757 10.4251C4.64344 10.2304 4.96002 10.2314 5.15467 10.4273ZM10.5 5.5C11.0523 5.5 11.5 5.94772 11.5 6.5C11.5 7.05228 11.0523 7.5 10.5 7.5C9.94772 7.5 9.5 7.05228 9.5 6.5C9.5 5.94772 9.94772 5.5 10.5 5.5ZM5.5 5.5C6.05228 5.5 6.5 5.94772 6.5 6.5C6.5 7.05228 6.05228 7.5 5.5 7.5C4.94772 7.5 4.5 7.05228 4.5 6.5C4.5 5.94772 4.94772 5.5 5.5 5.5Z"
                            fill="#212121" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_5853_36336">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </span>
        <div v-click-outside="onClickOutside" class="emoji-box" v-if="emojiPickerVisibilityForSubjectLine">
            <!-- <VuemojiPicker @emojiClick="handleEmojiClickForSubjectLine" /> -->
            <Picker :data="emojiIndex" set="facebook" :showPreview="false" :emojiTooltip="false" @select="handleEmojiClickForSubjectLine" />
        </div>
    </div>
    <dropdownlist :data-items="inerstTag" :text-field="'text'" :data-item-key="'id'"
        :value="insertTagValueForSubjectLine" @change="insertTagHandlerForSubjectLine">
    </dropdownlist>
</div>