<template src="./SubjectLineComponent.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
// import { VuemojiPicker } from "vuemoji-picker";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import vClickOutside from "click-outside-vue3";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
    dropdownlist: DropDownList,
    // VuemojiPicker,
    Picker,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: null,
    },
    onSync: {
      type: Function,
      default: () => { },
    },
    placeHolder: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      emojiIndex: new EmojiIndex(data),
      subjectLine: "",
      clubId: this.$route.params.id,
      emojiPickerVisibilityForSubjectLine: false,
      inerstTag: [
        { text: "Insert Tag", value: "", id: 0 },
        { text: "First Name", value: "@Model.FirstName", id: 1 },
        { text: "Last Name", value: "@Model.LastName", id: 2 },
        { text: "Club Name", value: "@Model.ClubName", id: 3 },
      ],
      insertTagValueForSubjectLine: { text: "Insert Tag", value: "", id: 0 },
    };
  },
  mounted() {
    this.subjectLine = this.value;
  },
  computed: {},
  methods: {
    checkIfIndexPresent() {
      if (this.index !== null) {
        return true;
      } else {
        return false;
      }
      // return this.index ?? false;
    },
    onClickOutside(event) {
      this.emojiPickerVisibilityForSubjectLine = false;
    },
    emojiToggleHandlerForSubjectLine() {
      this.emojiPickerVisibilityForSubjectLine =
        !this.emojiPickerVisibilityForSubjectLine;
    },
    inputHandler(event) {
      this.subjectLine = event.target.value;
      this.onSyncHandler();
    },
    handleEmojiClickForSubjectLine(e) {
      const element = this.$refs.subjectLineInput._input;
      const start = this.subjectLine.substring(0, element.selectionStart);
      const end = this.subjectLine.substring(element.selectionStart);
      const text = start + `${e?.native}` + end;
      this.subjectLine = text;
      this.$refs.subjectLineInput.focus();
      this.onSyncHandler();
    },

    insertTagHandlerForSubjectLine(e) {
      const element = this.$refs.subjectLineInput._input;
      const start = this.subjectLine.substring(0, element.selectionStart);
      const end = this.subjectLine.substring(element.selectionStart);
      const text = start + `${e.value.value}` + end;
      this.subjectLine = text;
      this.$refs.subjectLineInput.focus();
      this.onSyncHandler();
    },

    onSyncHandler() {
      if (this.checkIfIndexPresent() != false) {
        this.onSync(this.subjectLine, this.index);
      } else {
        this.onSync(this.subjectLine);
      }
    },
  },
};
</script>

<style></style>
