<template>
  <modal
    :title="'Add UTM Parameter'"
    :handler="true"
    :isLoading="isLoading"
    :cssClass="'modal-md-3'"
    @modalHandler="modalHandler"
    type="pulsing"
  >
    <template v-slot:content>
      <div class="k-form-field">
        <label>UTM Parameter</label>
        <kinput
          :class="'h-44'"
          :placeholder="'Field Label'"
          v-model="utmObject.utmKey"
          @keyup="errors.utmKey = ''"
        />
        <span class="k-form-error font-10-vsm">{{ this.errors?.utmKey }}</span>
      </div>
      <div class="k-form-field">
        <label>{{ isFromFlowAutomation ? "Flow" : "Campaign" }} Value</label>
        <dropdownlist
          :class="'h-44 drop-icon-2 mb-24'"
          :data-items="utmDropdownvalues"
          :text-field="'utmFieldLabel'"
          :data-item-key="'id'"
          :group-field="'groupTitle'"
          :value-field="'utmFieldName'"
          :value-primitive="true"
          @change="errors.utmValue = ''"
          v-model="utmObject.utmValue"
        />
        <span class="k-form-error font-10-vsm">{{
          this.errors?.utmValue
        }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <div class="me-1">
          <kbutton
            @click="modalHandler"
            :fill-mode="'outline'"
            :class="'w-100-vsm h-38 border-normal-1 text-color bg-white hvr-bg-primary flex-none h-44'"
            :theme-color="'primary'"
            >Cancel</kbutton
          >
        </div>
        <div class="ms-1">
          <kbutton
            :theme-color="'primary'"
            :class="'flex-none h-44'"
            @click="saveHandler"
            >Save
          </kbutton>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import modal from "@/components/common/AppModal/AppModal.vue";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";

export default {
  components: {
    kbutton: Button,
    modal,
    kinput: Input,
    dropdownlist: DropDownList,
  },
  computed: {},
  props: {
    utmDropdownvalues: {
      type: Array,
      required: false,
    },
    isFromFlowAutomation: {
      type: Boolean,
      required: false,
    },
  },
  data: function () {
    return {
      utmObject: {
        utmValue: "",
        utmKey: "",
      },
      errors: {},
    };
  },
  watch: {
    utmDropdownvalues: {
      handler: function (val) {
        if (val.length > 0) {
          this.utmObject.utmValue = val[0].utmFieldName;
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    modalHandler() {
      this.$emit("close", false);
    },
    saveHandler() {
      this.errors = {};
      if (!this.utmObject.utmKey) {
        this.errors.utmKey = "Please enter UTM Parameter";
      }
      if (!this.utmObject.utmValue) {
        this.errors.utmValue = "Please select Campaign Value";
      }

      if (Object.keys(this.errors).length === 0) {
        this.$emit("addUtmHandler", this.utmObject);
      }
    },
  },
};
</script>
