<modal
  title="Verify Your Domain"
  :handler="true"
  :cssClass="'modal-xl2 max-height-50-100 modal-table-scroll'"
  @modalHandler="onCancel"
>
  <template v-slot:content>
    <app-loader v-if="isLoader"></app-loader>
    <p class="font-14 l-height-17 color-gray mb-24">
      Copy the below records and add to your DNS to complete the process of
      domain verification. Once you are done, click the Verify button below to
      finish settung up.
    </p>
    <div class="mb-3">
      <Grid
        class="verify-domain-table"
        ref="grid"
        :columns="columns"
        :data-items="tableData"
      >
        <template v-slot:hostCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <span class="word-break-all pe-3">{{props.dataItem.host}}</span>
              <button
                @click="copyToClipboard(props.dataItem.host)"
                class="k-icon k-i-copy border-0 color-black"
              ></button>
            </div>
          </td>
        </template>
        <template v-slot:valueCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <span class="word-break-all pe-3">{{props.dataItem.value}}</span>
              <button
                @click="copyToClipboard(props.dataItem.value)"
                class="k-icon k-i-copy border-0 color-black"
              ></button>
            </div>
          </td>
        </template>

        <GridNoRecords> No records available. </GridNoRecords>
      </Grid>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton @click="onCancel" :class="'flex-none'">Cancel</kbutton>
      </div>
      <div class="ms-1">
        <kbutton @click="onVerify" :class="'flex-none'" :theme-color="'primary'"
          >Verify</kbutton
        >
      </div>
    </div>
  </template>
</modal>
